import { useState, useEffect } from "react";

import getQuery from "../../common/getQuery";

import { EventBus } from "../../event";
import { socket } from "../../socket";

// Methods

// API
import fetchBreak from "./methods/fetchBreak";
import fetchCityCodes from "./methods/fetchCityCodes";
import ExchangeService from "../../services/Exchange";

const GetExchangesTechBreakCityCodes = () => {
  let [techBreak, setTechBreak] = useState(null);
  let [urlCurr, setUrlCurr] = useState(null);
  let [exchanges, setExchanges] = useState([]);
  let [cities, setCities] = useState([]);

  let query = getQuery();

  useEffect(() => {
    let curFrom = query.get("cur_from");
    let curTo = query.get("cur_to");
    if (curFrom && curTo) {
      setUrlCurr({ from: curFrom, to: curTo });
    }
    socket.on("update break m", (technicalBreak) => {
      setTechBreak(technicalBreak);
    });

    EventBus.$emit("show_progress_bar", true);
    Promise.all([fetchBreak(), ExchangeService.getAll(), fetchCityCodes()])
      .then((values) => {
        setTechBreak(values[0]?.data);
        setExchanges(values[1]?.data);
        setCities(values[2]?.data);
      })
      .catch((error) => {
        console.error("Произошла ошибка:", error);
      })
      .finally(() => {
        EventBus.$emit("show_progress_bar", false);
      });

    return () => {
      socket.off("update break m");
      // if (exchangeId) {
      //   socket.off(`update_exchange_${exchangeId}`);
      // }
    };
  }, []);

  return {
    techBreak,
    urlCurr,
    setUrlCurr,
    exchanges,
    cities,
  };
};

export default GetExchangesTechBreakCityCodes;
