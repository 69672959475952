import ReceiptTwoToneIcon from "@mui/icons-material/ReceiptTwoTone";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import PaidIcon from "@mui/icons-material/Paid";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import {
  Box,
  Card,
  styled,
  Typography,
  CardActionArea,
  Avatar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";

function NavBar() {
  const { pathname } = useLocation();
  let theme = useTheme();

  const routes = [
    {
      name: "Заявки",
      icon: ReceiptTwoToneIcon,
      path: "/profile/orders",
      gradient: 'linear-gradient(to right, #f12711, #f5af19)',
    },

    {
      name: "Реквизиты",
      icon: CreditCardOutlinedIcon,
      path: "/profile/cards",
      gradient: 'linear-gradient(to right, #1f4037, #99f2c8)',
    },
    {
      name: "Настройки",
      icon: ManageAccountsOutlinedIcon,
      path: "/profile/settings",
      gradient: 'linear-gradient(to right, #7f7fd5, #86a8e7, #91eae4)',
    },
    {
      name: "Реферальная программа",
      icon: GroupAddIcon,
      path: "/profile/referral",
      gradient: 'linear-gradient(to right, #fdc830, #f37335)',
    },
    {
      name: "Кэшбек",
      icon: CardGiftcardIcon,
      path: "/profile/cashback",
      gradient: 'linear-gradient(to right, #fdc830, #f37335)',
    },
    {
      name: "Вывод вознаграждений",
      icon: PaidIcon,
      path: "/profile/withdraw",
      gradient: 'linear-gradient(to right, #fdc830, #f37335)',
    },
  ];



  return (
    <Box
      sx={{
        display: "flex",
        minWidth: "100%",
        overflowX: "scroll",
        py: 1,
      }}
    >
      {routes.map((r) => {
        let currentPage = r.path == pathname;

        return (
          <Card
            sx={{
              // bgcolor: currentPage ? theme.colors.alpha.black[10] : "",
              mr: 1,
              minWidth: "max-content",
              transition: "transform 0.2s",
              transform: currentPage ? "translateY(-10px)" : "",
            }}
          >
            <CardActionArea
              component={NavLink}
              to={r.path}
              sx={{ py: 1, px: 1.5, display: "flex" }}
            >
              <Avatar sx={{ background: r.gradient, width: '40px', height: '40px' }}>
                <r.icon />
              </Avatar>
              <Typography variant="body1" sx={{ ml: 1 }}>
                {r.name}
              </Typography>
            </CardActionArea>
          </Card>
        );
      })}
    </Box>
  );
}

export default NavBar;
