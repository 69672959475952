import React, { useState, useEffect, useMemo } from "react";
import { Container, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import getStatusText from "./methods/getStatusText";
import CardService from "../../services/Card";
import Add from "./Add";
import Resolved from "./Resolved";
import Rejected from "./Rejected";
import Process from "./Process";
import { socket } from "../../socket";
// import { observer } from "mobx-react";
import ProfileStore from "../../store/profile";
import { NavLink } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

function CreditCard() {
  let [card, setCard] = useState(null);
  let { id } = useParams();
  let [loaded, setLoaded] = useState(true);

  function updateCard(c) {
    setCard((card) => {
      if (card && card._id == c._id) {
        return c;
      } else {
        return card;
      }
    });
  }

  useEffect(() => {
    if (id !== "add") {
      CardService.getOne(id)
        .then((res) => {
          let data = res.data;
          setCard(data);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setLoaded(false);
        });
    } else {
      setLoaded(false);
    }

    socket.on("update card", (c) => {
      updateCard(c);
    });

    return () => {
      setCard(null);
      socket.off("update card");
    };
  }, []);

  let statusText = useMemo(() => getStatusText(card), [card]);

  if (loaded) return null;

  function view(statusText) {
    if (!statusText) return null;

    switch (statusText) {
      case "resolved":
        return <Resolved data={card} />;
      case "process":
        return <Process data={card} />;
      case "rejected":
        return <Rejected data={card} />;
      case "add":
        return <Add setCard={setCard} />;
    }
  }

  return (
    <Container sx={{ mt: 3 }} maxWidth="md">
      {ProfileStore.isAuth && (
        <Button
          sx={{ mb: 2, color: "#475259c4" }}
          startIcon={<ArrowBackIosIcon />}
          component={NavLink}
          to="/profile/cards"
        >
          Карты
        </Button>
      )}

      {view(statusText)}
    </Container>
  );
}

export default CreditCard;
