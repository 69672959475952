import getPrecision from "./getPrecision";
import getExchangeAmount from "./getExchangeAmount";

export default function convertToRub(
  exchange,
  amount,
  type,
  withFormula = false
) {
  let formula = exchange.rate.formula;

  console.log(formula, "formula");

  if (type === "take") {
    let result;
    const takeCode = exchange.currency.take.code;
    const takeValue = exchange.currency.take.mvalue;
    const precision = exchange.currency.take.precision;

    if (takeCode === "RUB") {
      result = getPrecision(precision, amount);
    } else {
      result = getPrecision(precision, amount * takeValue);
    }

    if (withFormula) {
      result = getPrecision(precision, result * formula);
    }
    return result;
  } else {
    const giveValue = exchange.currency.give.mvalue;
    const precision = exchange.currency.give.precision;
    let result = getPrecision(precision, giveValue * +amount);

    if (withFormula) {
      result = getExchangeAmount(exchange, "take", amount, true);
    }
    return result;
  }
}
