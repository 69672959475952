import React, { useState } from "react";
import { Box, Button, Typography, CircularProgress } from "@mui/material";
import ExchangePair from "./ExchangePair";

import getExchangeAmount from "../../common/getExchangeAmount";
import getCommissionBtc from "../../pages/Exchanger/methods/getCommissionBtc";
import getPrecision from "../../common/getPrecision";

function VmnvSection({
  style = {},
  exchange,
  takeAmount,
  loaded,
  submitHandler,
}) {
  function getGiveAmount() {
    let give = getExchangeAmount(exchange, "give", +takeAmount);

    const commissionBTC = getCommissionBtc(
      exchange,
      takeAmount,
      give,
      "take",
      () => {},
      () => {}
    );

    let precision = exchange.currency.give.precision;

    if (commissionBTC) {
      const calcAmountGive = getPrecision(precision, give - commissionBTC);
      if (calcAmountGive > 0) {
        give = calcAmountGive;
      } else {
        give = 0;
      }
    }

    return give;
  }

  let giveAmount = getGiveAmount();

  return (
    <Box
      sx={{
        p: 1,
        borderRadius: 2,
        bgcolor: "#6f6f6f2e",
        ...style,
      }}
    >
      <Typography variant="body2" sx={{ mb: 1 }} fontWeight={400}>
        Если Вы, по каким либо причинам не можете осуществить верификацию, то
        можете продолжить обмен:
      </Typography>
      <ExchangePair
        take={{
          name: exchange.currency.take.name,
          code: exchange.currency.take.code,
          img: exchange.currency.take.image,
          amount: takeAmount,
        }}
        give={{
          name: exchange.currency.give.name,
          code: exchange.currency.give.code,
          img: exchange.currency.give.image,
          amount: giveAmount,
        }}
      />
      <Button
        onClick={() => submitHandler(takeAmount, giveAmount)}
        type="submit"
        fullWidth
        sx={{ mt: 1 }}
        variant="contained"
        startIcon={loaded ? <CircularProgress size="1rem" /> : null}
        disabled={loaded}
      >
        Создать
      </Button>
    </Box>
  );
}

export default VmnvSection;
