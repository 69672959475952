export default function getCity(exchange, cities) {
    if (cities.length == 0 || !exchange) return null;
    let take = exchange.currency.take;
    let give = exchange.currency.give;

    if (take.isCash && take.cityCashCode) {
        let find = cities.find(c => c.code == take.cityCashCode);
        return {
            side: "take",
            value: find.name
        };
    }
    if (give.isCash && give.cityCashCode) {
        let find = cities.find(c => c.code == give.cityCashCode);
        return {
            side: "give",
            value: find.name
        };
    }
}
