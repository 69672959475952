import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Grid,
  Tooltip,
  styled,
  Avatar,
  Box,
  CircularProgress,
  CardActionArea,
  Typography,
} from "@mui/material";
import CardItem from "./CardItem";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import ProfileStore from "../../store/profile";
import CardService from "../../services/Card";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { EventBus } from "../../event";
import { socket } from "../../socket";

function CardList() {
  let [cards, setCards] = useState([]);
  let [loaded, setLoaded] = useState(false);

  useEffect(() => {
    EventBus.$emit("show_progress_bar", true);
    CardService.getAll()
      .then((res) => {
        let data = res.data;
        setCards(data);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoaded(true);
        EventBus.$emit("show_progress_bar", false);
      });
    socket.on("update card", (c) => {
      updateCard(c);
    });
    socket.on("add card", (c) => {
      addCard(c);
    });

    return () => {
      socket.off("update card");
      socket.off("add card");
    };
  }, []);

  function updateCard(c) {
    setCards((cards) => {
      return cards.map((card) => {
        if (card._id == c._id) {
          return c;
        } else {
          return card;
        }
      });
    });
  }

  function addCard(data) {
    setCards((cards) => {
      if (cards.find((c) => c._id == data._id)) return cards;
      return [data, ...cards];
    });
  }

  let showAddCard = ProfileStore.isPermanent;

  if (!loaded)
    return (
      <Box
        sx={{
          my: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box>
      {!cards.length && !showAddCard && (
        <Card sx={{ p: 1 }}>
          <Typography variant="subtitle1" align="center" fontWeight={400}>
            Ваш список реквизитов пуст
          </Typography>
        </Card>
      )}

      <Grid justifyBetween="stretch" container spacing={2}>
        {showAddCard && (
          <Grid item xs={12} md={6} lg={6}>
            <Tooltip arrow title="Нажмите чтобы добавить новую карту">
              <Link
                sx={{ "text-decoration": "none", height: "100%" }}
                to={`/cards/add`}
              >
                <Card sx={{ height: "100%" }}>
                  <CardActionArea
                    sx={{
                      px: 1,
                      height: "100%",
                    }}
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <AddTwoToneIcon fontSize="large" />
                      <Typography variant="h5" sx={{ ml: 2 }} fontWeight={400}>
                        Добавить
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
            </Tooltip>
          </Grid>
        )}

        {cards.map((c) => (
          <Grid key={c._id} item xs={12} md={6} lg={6}>
            <Link to={`/cards/${c.hash}`} sx={{ "text-decoration": "none" }}>
              <CardItem data={c} />
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default observer(CardList);
