export default function getStatusText(card) {
  if (!card) return "add";
  let status = card.status;

  switch (status) {
    case 101:
      return "process";
    case 102:
      return "resolved";
    case 103:
      return "rejected";
    case 104:
      return "rejected";
  }
}
