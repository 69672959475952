import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Card,
  Typography,
  Divider,
  TextField,
  CircularProgress,
  Button,
  Box,
  Grid,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import StatusCardIndicator from "./StatusCardIndicator";
import PhotoDesc from "./PhotoDesc";
import Info from "./Info";
import ccFormat from "../../common/ccFormat.js";
import UploadFile from "./UploadFile";
import SaveIcon from "@mui/icons-material/Save";
import CardService from "../../services/Card";
import base64toBlob from "../../common/base64toBlob";
import ProfileStore from "../../store/profile";
import { observer } from "mobx-react";

function Rejected({ data }) {
  let isAuth = ProfileStore.isAuth;
  let [image, setImage] = useState(null);
  let [loaded, setLoaded] = useState(false);
  let { number } = data;
  number = ccFormat(number.replace(/\s/g, ""));

  let comment = data.comment && data.comment.client;
  let banned = data && data.status === 104;

  function reverifyCardHandler() {
    if (!image) return;
    setLoaded(true);

    let form = new FormData();

    form.append("image", image);

    CardService.reverify(form, data.hash)
      .then((res) => {
        let data = res.data;
        console.log(data, "data");
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoaded(false);
      });
  }

  return (
    <Card>
      <Grid container columns={14}>
        <Grid item xs={14} md={8}>
          <Box sx={{ p: 2 }}>
            <Info title={`Карта ${number} отклонена`} />
            <PhotoDesc />{" "}
          </Box>
        </Grid>
        <Grid sx={{ bgcolor: "#d3d3d36e" }} item xs={14} md={6}>
          <Box
            sx={{
              p: 2,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <StatusCardIndicator
              status="rejected"
              comment={comment}
              banned={banned}
            />
            {!banned && (
              <>
                <UploadFile styles={{ width: "100%" }} setImage={setImage} />
                {image && (
                  <Button
                    fullWidth
                    onClick={reverifyCardHandler}
                    sx={{ mt: 1 }}
                    variant="contained"
                    startIcon={loaded ? <CircularProgress size="1rem" /> : null}
                    disabled={loaded}
                  >
                    Отправить
                  </Button>
                )}
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}

export default observer(Rejected);
