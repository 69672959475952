import sortCurrencies from "../../../common/sortCurrencies";

export default function takeCurrencies(exchanges) {
  return exchanges
    .map((e) => e.take)
    .filter((c, i, arr) => {
      return arr.findIndex((item) => item._id === c._id) === i;
    })
    .sort(sortCurrencies)
    .sort((a, b) => a.sort - b.sort);
}
