export default function getCardStatusName(status) {
  switch (status) {
    case "verification":
      return "Новая";
    case "resolved":
      return "Одобрена";
    case "rejected":
    case "banned":
      return "Отклонена";
    default:
      return "";
  }
}
