import React, { useState } from "react";
import {
  Box,
  TextField,
  CircularProgress,
  Button,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm, Controller } from "react-hook-form";
import ProfileStore from "../../store/profile";
import Visibility from "@mui/icons-material/Visibility";
import { useSnackbar } from "notistack";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function PasswordSettings() {
  const { enqueueSnackbar } = useSnackbar();
  let [isSubmitting, setSubmiting] = useState(false);
  let [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: { oldPassword: "", newPassword: "", newPasswordConfirm: "" },
  });

  function deleteAccount() {
    if (
      window.confirm(
        "Вы точно хотите удалить аккаунт? После удаления, доступ к нему навсегда будет утерян!"
      )
    ) {
      ProfileStore.deleteProfile(
        () => {
          enqueueSnackbar("Аккаунт удалён!", { variant: "success" });
        },
        () => {}
      );
    }
  }

  const onSubmit = handleSubmit((data) => {
    let { oldPassword, newPassword, newPasswordConfirm } = data;

    if (newPassword !== newPasswordConfirm) {
      setError("newPasswordConfirm", {
        type: "custom",
        message: "Пароли не совпадают",
      });
      return;
    }

    let reqData = {
      old: oldPassword,
      new: newPassword,
    };

    setSubmiting(true);

    ProfileStore.changePassword(
      reqData,
      () => {
        enqueueSnackbar("Пароль изменен!", { variant: "success" });
      },
      (e) => {
        if (e.password) {
          setError("oldPassword", {
            type: "custom",
            message: e.error,
          });
        } else {
          setError("newPassword", {
            type: "custom",
            message: e.error,
          });
        }
      },
      () => {
        setSubmiting(false);
      }
    );
  });
  return (
    <Box>
      <form noValidate onSubmit={onSubmit}>
        <Typography variant="h5" fontWeight="normal">
          Смена пароля
        </Typography>
        <Controller
          name={"oldPassword"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              {...register("oldPassword", {
                required: "Обязательное поле!",
                minLength: {
                  value: 6,
                  message: "Мин. длина пароля 6 символов!",
                },
                maxLength: 100,
              })}
              error={Boolean(errors.oldPassword?.message)}
              fullWidth
              margin="normal"
              helperText={errors.oldPassword?.message}
              label="Старый пароль"
              name="oldPassword"
              size="small"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              onChange={onChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={value}
            />
          )}
        />
        <Controller
          name={"newPassword"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              {...register("newPassword", {
                required: "Обязательное поле!",
                minLength: {
                  value: 6,
                  message: "Мин. длина пароля 6 символов!",
                },
                maxLength: 100,
              })}
              error={Boolean(errors.newPassword?.message)}
              fullWidth
              size="small"
              margin="normal"
              helperText={errors.newPassword?.message}
              label="Новый пароль"
              name="newPassword"
              type="password"
              variant="outlined"
              onChange={onChange}
              value={value}
            />
          )}
        />
        <Controller
          name={"newPasswordConfirm"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              {...register("newPasswordConfirm", {
                required: "Обязательное поле!",
                minLength: {
                  value: 6,
                  message: "Мин. длина пароля 6 символов!",
                },
                maxLength: 100,
              })}
              error={Boolean(errors.newPasswordConfirm?.message)}
              fullWidth
              margin="normal"
              helperText={errors.newPasswordConfirm?.message}
              label="Подтверждение нового пароля"
              name="newPasswordConfirm"
              size="small"
              type="password"
              variant="outlined"
              onChange={onChange}
              value={value}
            />
          )}
        />

        <Button
          sx={{
            mt: 1,
          }}
          color="primary"
          startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
          disabled={isSubmitting}
          type="submit"
          fullWidth
          size="large"
          variant="outlined"
        >
          Изменить пароль
        </Button>
      </form>
      <Button
        fullWidth
        color="error"
        sx={{
          mt: 4,
        }}
        variant="contained"
        onClick={deleteAccount}
        startIcon={<DeleteIcon />}
      >
        Удалить аккаунт
      </Button>
    </Box>
  );
}

export default PasswordSettings;
