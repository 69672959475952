import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Button,
  TextField,
  Box,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import PhotoDesc from "./PhotoDesc";
import UploadFile from "./UploadFile";
import Info from "./Info";
import CardService from "../../services/Card";
import InputMask from "react-input-mask";
import luhnValidate from "../../common/luhnValidate";
import { EventBus } from "../../event";

function Add({ setCard }) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: { name: "", number: "" },
  });
  let [image, setImage] = useState(null);
  let [loaded, setLoaded] = useState(false);

  const onSubmit = handleSubmit((data) => {
    let { name, number } = data;
    if (!image) return;
    setLoaded(true);

    let form = new FormData();

    form.append("image", image);
    form.append("number", number);
    form.append("name", name);

    CardService.add(form)
      .then((res) => {
        let card = res.data;
        setCard(card);
        navigate(`/cards/${card._id}`);
      })
      .catch((e) => {
        console.error(e);
        let message = e.response?.data?.error;

        if (message) {
          EventBus.$emit("show_snackbar", { mode: "error", text: message });
        }
      })
      .finally(() => {
        setLoaded(false);
      });
  });

  function cardNumberValidate(number) {
    let isValid = luhnValidate(number);
    return isValid;
  }

  return (
    <Card>
      <Grid container columns={14}>
        <Grid item xs={14} md={8}>
          <Box sx={{ p: 2 }}>
            <Info title={`Добавить карту`} />
            <PhotoDesc />
          </Box>
        </Grid>
        <Grid sx={{ bgcolor: "#d3d3d36e" }} item xs={14} md={6}>
          <Box
            component="form"
            sx={{
              p: 2,
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
            noValidate
            onSubmit={onSubmit}
          >
            <UploadFile setImage={setImage} />
            <Box>
              <Controller
                name={"name"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    error={Boolean(errors.name?.message)}
                    {...register("name", {
                      required: "Обязательное поле!",
                      minLength: {
                        value: 5,
                        message: "Мин. длина имени 5 символов!",
                      },
                      maxLength: 100,
                    })}
                    fullWidth
                    sx={{ mt: 0, mb: 1 }}
                    InputProps={{
                      style: {
                        backgroundColor: "white",
                      },
                    }}
                    helperText={errors.name?.message}
                    label="ФИО"
                    name="name"
                    type="text"
                    variant="outlined"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <Controller
                name="number"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputMask
                    error={Boolean(errors.number?.message)}
                    helperText={errors.number?.message}
                    {...register("number", {
                      required: "Обязательное поле!",
                      validate: (val) => {
                        return (
                          cardNumberValidate(val.replace(/\D/g, "")) ||
                          "Некорректный номер карты"
                        );
                      },
                    })}
                    fullWidth
                    label="Номер карты"
                    sx={{ mt: 0, mb: 1 }}
                    InputProps={{
                      style: {
                        backgroundColor: "white",
                      },
                    }}
                    maskChar=" "
                    mask="9999 9999 9999 9999 99"
                    value={value}
                    onChange={onChange}
                    name="number"
                    type="text"
                  >
                    {(e) => <TextField {...e} />}
                  </InputMask>
                )}
              />
              <Button
                type="submit"
                fullWidth
                sx={{ mt: 1 }}
                variant="contained"
                startIcon={loaded ? <CircularProgress size="1rem" /> : null}
                disabled={loaded}
              >
                Добавить
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}

export default Add;
