import getExchangeAmount from "../../../common/getExchangeAmount";
import getCommissionBtc from "./getCommissionBtc";

export default function calculateTake(
  exchange,
  amountGive,
  setTake,
  setCommissionInfo,
  setMinLimit
) {

  let take = getExchangeAmount(exchange, "take", amountGive);
  const commissionBTC = getCommissionBtc(
    exchange,
    take,
    amountGive,
    "give",
    setCommissionInfo,
    setMinLimit
  );
  if (commissionBTC) {
    const calcAmountTake = getExchangeAmount(
      exchange,
      "take",
      +amountGive + commissionBTC
    );
    if (calcAmountTake > 0) {
      take = calcAmountTake;
    } else {
      take = 0;
    }
  }

  // const commissionQiwi = this.getCommissionQiwi;
  // if (commissionQiwi) {
  //     const addGive = amountGive * commissionQiwi;
  //     const newGive = amountGive + addGive;
  //     const calcAmountTake = this.getExchangeAmount(this.exchange, "take", newGive);
  //     if (calcAmountTake > 0) {
  //         take = calcAmountTake;
  //     } else {
  //         take = 0;
  //     }
  // }
  setTake(+take);
}
