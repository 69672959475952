import { makeObservable, observable, action, autorun, flow } from "mobx";
import ContentService from "../services/Content";

class Content {
  content = null;
  constructor() {
    makeObservable(this, {
      content: observable,
      setContent: action,
      fetchContent: flow,
    });
  }

  setContent(data) {
    this.content = data;
  }

  *fetchContent(finishHandler) {
    ContentService.get()
      .then((res) => {
        this.setContent(res.data);
      })
      .finally(finishHandler);
  }
}

const content = new Content();

autorun(() => {
  console.log("Content data:", content.content);
});

export default content;
