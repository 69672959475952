import React from "react";
import {
  Button,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  Avatar,
  alpha,
  Tooltip,
  CardActionArea,
  styled,
  useTheme,
} from "@mui/material";
import moment from "moment";
import "moment/locale/ru";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import ExchangePair from "../Order/ExchangePair";
import Label from "../Label/Label";
import getOrderStatusText from "../../common/getOrderStatusText";
import getOrderStatusName from "../../common/getOrderStatusName";
import getImgUrl from "../../common/getImgUrl";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Badge from "../Badge";
moment.locale("ru");

function OrderCard({ data }) {
  let { _id, created } = data;
  let statusText = getOrderStatusText(data.status);
  let statusName = getOrderStatusName(statusText);

  var color = (function () {
    switch (statusText) {
      case "resolved":
        return "green";
      case "rejected":
        return "red";
      case "checked":
        return "yellow";
      default:
        return "yellow";
    }
  })();

  return (
    <Card
      sx={{
        "text-decoration": "none",
        height: "100%",
      }}
    >
      <CardActionArea
        sx={{
          py: 1,
          px: 2,
          height: "100%",

          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "stretch",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" fontWeight="normal">
            {_id}
          </Typography>

          <Badge color={color} name={statusName} />
        </Box>
        <ExchangePair
          hideValue
          style={{
            my: 1,
          }}
          take={{
            name: data.currency.take.name,
            code: data.currency.take.code,
            img: data.currency.take.image,
            amount: data.amount.take,
          }}
          give={{
            name: data.currency.give.name,
            code: data.currency.give.code,
            img: data.currency.give.image,
            amount: data.amount.give,
          }}
        />
        <Typography
          variant="body2"
          sx={{ alignSelf: "flex-end", color: "#475259c4" }}
          noWrap
        >
          {moment(created).format("D MMM YYYY, h:mm")}
        </Typography>
      </CardActionArea>
    </Card>
  );
}

export default OrderCard;
