import React from "react";
import {
  Container,
  Card,
  Typography,
  Divider,
  TextField,
  Box,
  styled,
  Grid,
} from "@mui/material";
import TemplateImg from "../../assets/images/card.png";

function PhotoDesc() {
  return (
    <Box sx={{ mt: 2 }}>
      <Grid alignItems="center" container spacing={2}>
        <Grid item sm={6}>
          <Typography variant="h6" fontWeight="normal" sx={{ mb: 1 }}>
            Условия фотографии:
          </Typography>
          <Typography sx={{ mt: 1, color: "#475259" }} variant="body2">
            <b>1.</b> Видно номер карты и имя владельца (можете замазать
            остальные данные);
          </Typography>
          <Typography sx={{ mt: 1, color: "#475259" }} variant="body2">
            <b>2.</b> На заднем фоне должен быть виден наш сайт "Menyatt.com".
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <img
            alt="Пример фотографии"
            style={{ borderRadius: "10px", width: "100%" }}
            component="img"
            src={TemplateImg}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default PhotoDesc;
