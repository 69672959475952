export default function validateAmountsOnBlur(
  side,
  takeAmount,
  giveAmount,
  changeAmount,
  errorHanlder,
  reserve,
  min,
  max,
  takeCode,
  giveCode
) {
  let errorData = {};

  if (takeAmount < min) {
    changeAmount("take", +min);
    errorData["take"] = `Мин суммa ${min} ${takeCode}`;
    // errorHanlder({ take: `Мин суммa ${min} ${takeCode}` });
  } else if (takeAmount > max) {
    changeAmount("take", +max);
    errorData["take"] = `Макс суммa ${max} ${takeCode}`;
    // errorHanlder({ take: `Макс суммa ${max} ${takeCode}` });
  }

  if (giveAmount > reserve) {
    changeAmount("give", +reserve);
    errorData["give"] = `Макс. резерв ${reserve} ${giveCode}`;
    // errorHanlder({ give: `Макс. резерв ${reserve} ${giveCode}` });
  }

  errorHanlder(errorData);
}
