import React from "react";
import { Typography, Box } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function ExchangePair({ style, take, give, horizontal, hideValue }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        ...style,
      }}
    >
      <CurrencyCard
        hideValue={hideValue}
        {...take}
        style={{ maxWidth: "45%" }}
      />
      <ArrowForwardIcon size="small" />
      <CurrencyCard
        hideValue={hideValue}
        {...give}
        style={{ maxWidth: "45%" }}
      />
    </Box>
  );
}

function CurrencyCard({ name, amount, code, value, style = {}, hideValue }) {
  return (
    <Box sx={{ ...style }}>
      <Typography variant="body1">{name}</Typography>
      <Typography noWrap variant="body2" sx={{ color: "#475259" }}>
        {amount} {code}
      </Typography>

      {!hideValue && (
        <Typography noWrap variant="body2">
          {value || "Неизвестно"}
        </Typography>
      )}
    </Box>
  );
}

export default ExchangePair;
