import axios from "axios";
import CONFIG from "./config";
import profileStore from "./store/profile";

axios.defaults.baseURL = CONFIG.API;
axios.defaults.headers.common["scode"] = "M";

axios.interceptors.request.use(function (req) {
  const token = localStorage.getItem("token");
  req.headers.common["token"] = token || null;
  return req;
});
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 403) {
      console.log("logout inst");
      profileStore.logout();
    }
    return Promise.reject(error);
  }
);
