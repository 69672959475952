import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Grid,
  Divider,
  Button,
  CircularProgress,
} from "@mui/material";

import Info from "../../components/Card/Info";
import PhotoDesc from "../../components/Card/PhotoDesc";
import UploadFile from "../../components/Card/UploadFile";
import { useNavigate } from "react-router-dom";
import VmnvSection from "./VmnvSection";
import ExchangeService from "../../services/Exchange";
import ExchangePair from "./ExchangePair";
import { socket } from "../../socket";
import getExchangeAmount from "../../common/getExchangeAmount";
import getCommissionBtc from "../../pages/Exchanger/methods/getCommissionBtc";
import getPrecision from "../../common/getPrecision";
import OrderService from "../../services/Order";
import ProfileStore from "../../store/profile";
import { EventBus } from "../../event";

function VerifyCard({ data }) {
  let navigate = useNavigate();
  let [image, setImage] = useState(null);
  let [loaded, setLoaded] = useState(false);
  let [noVerifyLoaded, setNoVerifyLoaded] = useState(false);
  let [vmnv, setVmnv] = useState(null);
  let [exchange, setExchange] = useState(null);

  useEffect(() => {
    ExchangeService.getOne({ id: data.exchange })
      .then((res) => {
        let data = res.data;
        socket.on(`update_exchange_${data._id}`, (exc) => {
          setExchange(exc);
        });
        setExchange(data);
      })
      .catch((e) => {
        console.log(e);
      });

    ExchangeService.getOne({ id: data.exchange, uniqueType: "vmnv" })
      .then((res) => {
        let data = res.data;
        socket.on(`update_exchange_${data._id}`, (exc) => {
          setVmnv(exc);
        });
        setVmnv(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  function getGiveAmount() {
    if (!exchange) return 0;
    let give = getExchangeAmount(exchange, "give", +data.take);

    const commissionBTC = getCommissionBtc(
      exchange,
      data.take,
      give,
      "take",
      () => {},
      () => {}
    );

    let precision = exchange.currency.give.precision;

    if (commissionBTC) {
      const calcAmountGive = getPrecision(precision, give - commissionBTC);
      if (calcAmountGive > 0) {
        give = calcAmountGive;
      } else {
        give = 0;
      }
    }

    return give;
  }

  function noVerifyCreate(takeAmount, giveAmount) {
    if (noVerifyLoaded) return;
    setNoVerifyLoaded(true);
    let noVerifyData = Object.assign({}, data, {
      take: takeAmount,
      give: giveAmount,
      exchange: vmnv._id,
    });
    fetchHandler(noVerifyData);
  }
  function fetchHandler(resData) {
    OrderService.create(resData)
      .then((res) => {
        let data = res.data;
        console.log(data, "data");
        let user = data.user;
        let token = data.token;
        let orderToken = data.orderToken;
        let order = data.order;

        if (user) {
          localStorage.setItem("token", token);
          ProfileStore.setProfile(user);
        }
        if (orderToken) {
          localStorage.setItem("orderToken", orderToken);
        }
        navigate(`/orders/${order.hash}`);
      })
      .catch((e) => {
        console.log(e, "e");
        let error = e?.response?.data?.error;
        if (error) {
          EventBus.$emit("show_snackbar", { mode: "error", text: error });
        }
      })
      .finally(() => {
        setNoVerifyLoaded(false);
      });
  }

  function cancelCreate() {
    navigate(`/`);
  }

  function verifyCreate() {
    if (loaded) return;
    if (!image) return;
    setLoaded(true);

    let form = new FormData();

    form.append("data", JSON.stringify(data));
    form.append("dest", "card");
    form.append("image", image);

    fetchHandler(form);
  }

  let giveAmount = getGiveAmount();

  return (
    <Card>
      <Box sx={{ pt: 2, px: 2 }}>
        <Grid container spacing={2} columns={14}>
          <Grid item xs={14} md={8}>
            <Info title={`Верификация карты`} />
            <PhotoDesc />
          </Grid>
          <Grid
            item
            xs={14}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {vmnv && (
              <VmnvSection
                loaded={noVerifyLoaded}
                submitHandler={noVerifyCreate}
                takeAmount={data.take}
                exchange={vmnv}
              />
            )}
            <UploadFile style={{ flexGrow: 1 }} setImage={setImage} />
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ mt: 1 }} />

      <Box sx={{ px: 2, py: 1 }}>
        <Grid alignItems="center" container spacing={2}>
          <Grid item xs={12} md={6}>
            {exchange && (
              <ExchangePair
                style={{ justifyContent: "space-between" }}
                take={{
                  value: data.fields.take.find((f) => f.isAddress || f.isCard)
                    ?.value,
                  name: exchange.currency.take.name,
                  code: exchange.currency.take.code,
                  img: exchange.currency.take.image,
                  amount: data.take,
                }}
                give={{
                  value: data.fields.give.find((f) => f.isAddress || f.isCard)
                    ?.value,
                  name: exchange.currency.give.name,
                  code: exchange.currency.give.code,
                  img: exchange.currency.give.image,
                  amount: giveAmount,
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                alignItms: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={cancelCreate}
                variant="contained"
                color="error"
                disabled={loaded}
              >
                Отменить
              </Button>
              {image && (
                <Button
                  onClick={verifyCreate}
                  // fullWidth
                  sx={{ ml: 1 }}
                  variant="contained"
                  startIcon={loaded ? <CircularProgress size="1rem" /> : null}
                  disabled={loaded}
                >
                  Создать заявку
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}
export default VerifyCard;
