import React, { useState, useMemo } from "react";
import { Box, Grid, MenuItem, Typography, Avatar } from "@mui/material";
import Nav from "./Nav";
import getFiltersByCurrencies from "../../../common/GetFiltersByCurrencies";
import getImgUrl from "../../../common/getImgUrl";

function CurrenciesList({
  currencies = [],
  side = "take",
  selected,
  setCurrency,
  closeHandler,
}) {
  let [search, setSearch] = useState("");
  let [activeFilter, setActiveFilter] = useState("");

  let filterList = useMemo(
    () => getFiltersByCurrencies(currencies),
    [currencies]
  );

  if (filterList.length !== 0 && !activeFilter) {
    setActiveFilter("all");
  }

  let filtredCurrencies = useMemo(() => {
    let result = [];
    if (activeFilter == "all") {
      result = currencies;
    } else {
      result = currencies.filter((c) => c.type === activeFilter);
    }
    if (search) {
      result = result.filter((c) => {
        return (
          c.name.toUpperCase().indexOf(search.toUpperCase()) > -1 ||
          c.code.toUpperCase().indexOf(search.toUpperCase()) > -1
        );
      });
    }

    return result;
  }, [activeFilter, search]);

  function onSelectCurrency(c) {
    setCurrency(c);
    closeHandler();
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Nav
        filterList={filterList}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        search={search}
        setSearch={setSearch}
        closeHandler={closeHandler}
      />
      <Grid
        container
        spacing={2}
        sx={{
          p: 2,
          "overflow-y": "scroll",
          flexGrow: 1,
          mt: "1px",
          maxHeight: "300px",
        }}
      >
        {filtredCurrencies.map((c) => {
          return (
            <Grid
              onClick={() => onSelectCurrency(c)}
              key={c._id}
              item
              xs={12}
              md={6}
              lg={4}
            >
              <CurrencyCard
                reserve={side == "give" ? c.reserve : ""}
                code={side == "give" ? c.code : ""}
                img={c.image}
                name={c.name}
                classes=""
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

function CurrencyCard({ img, name, code, reserve }) {
  return (
    <MenuItem
      touchable
      sx={{ display: "flex", alignItems: "center", maxWidth: "100%" }}
    >
      <Avatar src={getImgUrl(img)} sx={{ width: 45, height: 45 }} />
      <Box sx={{ ml: 1.5 }}>
        <Typography  sx={{ fontSize: "20px" }}>
          {name}
        </Typography>
        <Typography sx={{ color: "#475259", fontSize: "18px" }}>
          {reserve} {code}
        </Typography>
      </Box>
    </MenuItem>
  );
}

export default CurrenciesList;
