import React, { useEffect, useState } from "react";
import { Typography, Box, Container, Card, Grid, Avatar } from "@mui/material";
import ExchangeService from "../../services/Exchange";
import getCurrenciesInExchanges from "../../common/getCurrenciesInExchanges";
import CurrenciesFilter from "../../components/CurrenciesFilter";
import GetFiltersByCurrencies from "../../common/GetFiltersByCurrencies";
import { EventBus } from "../../event";
import getImgUrl from "../../common/getImgUrl";

function getCurrenciesByFilter(currencies, activeFilter) {
  let result = [];
  if (activeFilter == "all") {
    result = currencies;
  } else {
    result = currencies.filter((c) => c.type === activeFilter);
  }

  return result;
}

function ReservePage() {
  let [currencies, setCurrencies] = useState([]);
  let [activeFilter, setActiveFilter] = useState("all");

  useEffect(() => {
    EventBus.$emit("show_progress_bar", true);
    ExchangeService.getAll()
      .then((res) => {
        setCurrencies(getCurrenciesInExchanges(res.data));
        EventBus.$emit("show_progress_bar", false);
      })
      .catch((e) => console.error(e));
  }, []);

  if (!currencies.length) return null;

  let filterList = GetFiltersByCurrencies(currencies);

  let filtredCurrencies = getCurrenciesByFilter(currencies, activeFilter);

  if (filtredCurrencies.length == 0) return null;

  return (
    <Box>
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          textAlign="center"
          sx={{ mb: 5, mx: 1, fontWeight: 400 }}
        >
          Резервы
        </Typography>

        <CurrenciesFilter
          styles={{ mb: 1 }}
          filterList={filterList}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
        />

        <Card sx={{ p: 2.5 }}>
          <Grid container spacing={2}>
            {filtredCurrencies.map((c) => (
              <Grid key={c._id} item xs={12} sm={6} md={4} lg={3}>
                <CurrencyCard
                  name={c.name}
                  img={c.image}
                  reserve={c.reserve}
                  code={c.code}
                />
              </Grid>
            ))}
          </Grid>
        </Card>
      </Container>
    </Box>
  );
}

export default ReservePage;

function CurrencyCard({ img, name, code, reserve }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Avatar src={getImgUrl(img)} sx={{ width: 45, height: 45 }} />
      <Box sx={{ ml: 1.5 }}>
        <Typography noWrap sx={{ color: "#8595a6", fontSize: "16px" }}>
          {name}
        </Typography>
        <Typography sx={{ color: "#37373c", fontSize: "18px" }}>
          {reserve} {code}
        </Typography>
      </Box>
    </Box>
  );
}
