import getPrecision from "./getPrecision";

export default function getExchangeAmount(
  exchange,
  get,
  amount,
  inRub = false
) {
  let rate = exchange.rate;
  let reverse = rate.reverse;
  let service = rate.service;
  let take = exchange.currency.take;
  let give = exchange.currency.give;
  let precisionTake = take.precision;
  let precisionGive = give.precision;

  let result = 0;

  if (get == "give") {
    if (reverse === "left") {
      result = getPrecision(precisionGive, amount / service);
    } else if (reverse === "right") {
      result = getPrecision(precisionGive, service * amount);
    } else if (reverse === "none") {
      result = getPrecision(precisionGive, amount);
    }

    if (inRub) {
      result = result * give.mvalue;
    }
  } else {
    if (reverse === "left") {
      result = getPrecision(precisionTake, amount * service);
    } else if (reverse === "right") {
      result = getPrecision(precisionTake, amount / service);
    } else if (reverse === "none") {
      result = getPrecision({ precisionTake, amount });
    }
    if (inRub) {
      result = result * take.mvalue;
    }
  }

  if (result && result > 0) {
    return result;
  } else {
    return 0;
  }
}
