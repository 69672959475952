import React from "react";
import { Card, Grid, Button, Typography, Divider, Box } from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";

import { Link as RouterLink } from "react-router-dom";
import { observer } from "mobx-react";
import ProfileStore from "../../store/profile";
import CopyToClipboard from "../../components/CopyToClipboard/CopyToClipboard";
import History from "./History";
import imgBack from "../../assets/images/ref-back.png";

function getReferralHistory(profile) {
  if (profile.referral && profile.referral.history) {
    let l = profile.referral.history.filter((i) => i.sum !== 0);
    return l;
  } else {
    return [];
  }
}

function getReferralBalance(profile) {
  if (!profile.referral) {
    return 0;
  } else {
    return profile.referral.balance;
  }
}

function Referral() {
  let isVerified = ProfileStore.isVerified;
  let referralLink = ProfileStore.referralLink;

  let profileObj = ProfileStore.profile;

  let referralHistoryList = getReferralHistory(profileObj);
  let referralBalance = getReferralBalance(profileObj);


  if (!isVerified)
    return (
      <Card sx={{ p: 1 }}>
        <Typography variant="subtitle1" align="center">
          Для того чтобы воспользоваться <b>Реферальной программой</b> вам нужно
          активировать аккаунт по Email!
        </Typography>
      </Card>
    );

  return (
    <Card>
      <Typography sx={{ p: 2, py: 1 }} variant="h5" fontWeight="normal">
        Реферальная программа
      </Typography>
      <Grid container spacing={2} sx={{ p: 2, pt: 0 }}>
        <Grid item xs={12} sm={8}>
          <Typography variant="body1" fontWeight={400}>
            Реферальная программа - вид заработка для зарегистрированных
            пользователей нашего сервиса, в виде вознаграждений за обмены
            клиентов, которые пришли к нам по Вашей реферальной ссылке.
          </Typography>
          <Typography variant="body1" paragraph sx={{ mt: 1 }} fontWeight={400}>
            Делитесь партнерской ссылкой, привлекайте знакомых и зарабатывайте
            до 30% с прибыли каждой заявки.
          </Typography>
          <Typography variant="h6" fontWeight={400}>
            Реферальная ссылка
          </Typography>
          <Box
            sx={{
              borderRadius: 1.5,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 1,
              bgcolor: "#d3d3d36e",
              p: 1,
            }}
          >
            <Typography fontWeight={400} sx={{ mr: 2 }}>
              {referralLink}
            </Typography>
            <CopyToClipboard value={referralLink} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box sx={{ width: "100%" }}>
            <img src={imgBack} alt="Кэшбэк" width="100%" />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ p: 2, py: 1 }}>
        <Typography variant="h6" fontWeight={400}>
          Всего начисленно
        </Typography>
        <Box
          sx={{
            mt: 1,
            display: "flex",
            bgcolor: "#d3d3d36e",
            alignItems: "center",
            borderRadius: 1.5,
            p: 1,
            width: "max-content",
          }}
        >
          <Typography fontWeight={600} sx={{ fontSize: "24px" }}>
            {referralBalance} RUB
          </Typography>
          {referralBalance ? (
            <Button
              sx={{ ml: 2 }}
              component={RouterLink}
              to="/profile/withdraw"
              variant="contained"
              endIcon={<PaidIcon />}
            >
              Вывести
            </Button>
          ) : null}
        </Box>
      </Box>
      <Divider />
      <History list={referralHistoryList} />
    </Card>
  );
}

export default observer(Referral);
