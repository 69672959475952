import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton, Drawer } from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MainMenu from "./Main";
import { EventBus } from "../../event";
import bestch from "../../assets/images/best.png";
import { NavLink } from "react-router-dom";

function DrawerMenu() {
  let [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    EventBus.$on("open_menu", () => {
      setShowMenu(true);
    });
    return () => {
      EventBus.$off("open_menu");
    };
  }, []);
  return (
    <Box>
      <Drawer
        sx={{}}
        PaperProps={{
          sx: {
            borderRadius: "0px",
            background: `linear-gradient(#03CE97 5%, #00619A 90%)`,
            minWidth: {
              xs: "100%",
              sm: "350px",
            },
          },
        }}
        open={showMenu}
        onClose={() => setShowMenu(false)}
      >
        <Box
          onClick={() => setShowMenu(false)}
          sx={{
            py: 3,
            px: 4,
          }}
        >
          <Box
            sx={{
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              component={NavLink}
              to="/"
              sx={{ textShadow: "0px 0px 2px #000", color: 'white' }}
              variant="h5"
            >
              Menyatt.com
            </Typography>
            <IconButton
              onClick={() => setShowMenu(false)}
              sx={{ color: "white" }}
              size="large"
            >
              <MenuOpenIcon sx={{ fontSize: 35 }} />
            </IconButton>
          </Box>
          <MainMenu />

          <Box sx={{ mt: 4 }}>
            <Typography
              variant="body1"
              align="center"
              sx={{ textShadow: "0px 0px 2px #000", color: "white" }}
            >
              Cвязаться по почте
            </Typography>
            <Typography
              component="a"
              href="mailto:abc@example.com"
              sx={{
                textAlign: "center",
                fontSize: "24px",
                display: "block",
                color: "inherit",
              }}
            >
              support@menyatt.com
            </Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography
              variant="body1"
              align="center"
              sx={{ textShadow: "0px 0px 2px #000", color: "white" }}
            >
              Чат
            </Typography>
            <Typography align="center" variant="h5">
              Открыть
            </Typography>
          </Box>

          <Typography
            variant="body1"
            align="center"
            sx={{ textShadow: "0px 0px 2px #000", color: "white", mt: 4 }}
          >
            Наши партнёры
          </Typography>

          {/* <a
            alt="bestchange.com"
            style={{ marginTop: "20px", display: "block" }}
            href="https://www.bestchange.ru/"
            target="_blank"
          >
            <img
              alt="bestchange.com"
              style={{ margin: "0px auto", display: "block" }}
              src={bestch}
              width="200px"
            />
          </a> */}
        </Box>
      </Drawer>
    </Box>
  );
}

export default DrawerMenu;
