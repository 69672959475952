import React from "react";
import {
  Box,
  TextField,
  CircularProgress,
  Button,
  Typography,
  alpha,
  Card,
  Divider,
  Avatar,
  styled,
  useTheme,
} from "@mui/material";
import GaugeChart from "react-gauge-chart";

const CardWrapper = styled(Card)(
  ({ theme }) => `
      transition: ${theme.transitions.create(["box-shadow"])};
      position: relative;
      z-index: 5;
  
      &:hover {
          z-index: 6;
          box-shadow: 
              0 0.56875rem 3.3rem ${alpha(theme.colors.alpha.black[100], 0.05)},
              0 0.9975rem 2.4rem ${alpha(theme.colors.alpha.black[100], 0.07)},
              0 0.35rem 1rem ${alpha(theme.colors.alpha.black[100], 0.1)},
              0 0.225rem 0.8rem ${alpha(theme.colors.alpha.black[100], 0.15)};
      }
    `
);

export default function LevelInfo() {
  const theme = useTheme();
  return (
    <CardWrapper>
      <Box
        pb={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          sx={{
            fontSize: `${theme.typography.pxToRem(16)}`,
          }}
          variant="h3"
        >
          "Returning Visitors
        </Typography>
        <Typography variant="h4">
          {/* <Text color="warning">21,585</Text> */}
        </Typography>
      </Box>
      <Box
        sx={{
          mx: "auto",
          maxWidth: "340px",
        }}
      >
        <GaugeChart
          arcPadding={0.1}
          cornerRadius={3}
          textColor={theme.colors.alpha.black[70]}
          needleColor={theme.colors.alpha.black[50]}
          needleBaseColor={theme.colors.alpha.black[100]}
          colors={[theme.colors.warning.lighter, theme.colors.warning.main]}
          percent={50}
          animDelay={0}
        />
      </Box>
      <Divider />
      <Box pt={3} mb={2} display="flex" alignItems="center">
        <Avatar
          variant="rounded"
          sx={{
            mr: 1,
            width: 28,
            height: 28,
            p: 0,
            background: `${theme.colors.warning.lighter}`,
            color: `${theme.colors.warning.main}`,
          }}
        >
          {/* <KeyboardArrowDownTwoToneIcon /> */}
        </Avatar>
        <Typography variant="h5">
          {/* <Text color="warning">+ 1.25%</Text> */}
        </Typography>
      </Box>
      <Typography variant="subtitle2">
        You missed your target visitors numbers by 23.66%. Improve your ratings!
      </Typography>
    </CardWrapper>
  );
}
