import React, { useState } from "react";
import {
  Box,
  TextField,
  CircularProgress,
  Button,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import ProfileStore from "../../store/profile";
import ProfileService from "../../services/Profile";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import InputMask from "react-input-mask";

function CommonSettings() {
  const { enqueueSnackbar } = useSnackbar();
  let profile = ProfileStore.profile;

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: { name: profile.name, mobile: profile.mobile },
  });
  let [isSubmitting, setSubmiting] = useState(false);

  const onSubmit = handleSubmit((data) => {
    let phoneNumber = data.mobile.replace(/\D/g, "");
    if (phoneNumber && phoneNumber.length < 11) {
      setError("mobile", {
        type: "custom",
        message: "Не верный номер телефона",
      });
      return;
    }

    setSubmiting(true);
    ProfileService.changeProfile(data)
      .then((res) => {
        let data = res.data;
        console.log(data, "data");
        enqueueSnackbar("Данные изменены!", { variant: "success" });
      })
      .catch((e) => console.error(e))
      .finally(() => setSubmiting(false));
  });
  return (
    <form noValidate onSubmit={onSubmit}>
      <Typography variant="h5" fontWeight="normal">
        Общие
      </Typography>
      <TextField
        fullWidth
        margin="normal"
        disabled
        label="Email"
        name="email"
        type="text"
        size="small"
        variant="outlined"
        value={profile.email}
      />
      <Controller
        name={"name"}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            error={Boolean(errors.name?.message)}
            {...register("name", {
              required: "Поле не может быть пустым",
              minLength: {
                message: "Мин. 4 символов",
                value: 4,
              },
              maxLength: 200,
            })}
            fullWidth
            margin="normal"
            helperText={errors.name?.message}
            label="Имя"
            name="name"
            type="text"
            size="small"
            variant="outlined"
            onChange={onChange}
            value={value}
          />
        )}
      />

      <Controller
        name={"mobile"}
        control={control}
        render={({ field: { onChange, value } }) => (
          <InputMask
            error={Boolean(errors.mobile?.message)}
            helperText={errors.mobile?.message}
            {...register("mobile", {})}
            fullWidth
            label="Номер телефона"
            margin="normal"
            mask="+7 (999) 999-99-99"
            value={value}
            onChange={onChange}
            size="small"
            name="phone"
            type="text"
          >
            {(e) => <TextField {...e} />}
          </InputMask>
        )}
      />

      <Button
        sx={{
          mt: 1,
        }}
        color="primary"
        startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
        disabled={isSubmitting}
        type="submit"
        fullWidth
        size="large"
        variant="outlined"
      >
        Сохранить
      </Button>
    </form>
  );
}

export default observer(CommonSettings);
