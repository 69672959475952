import { Link as RouterLink } from "react-router-dom";
import PaidIcon from "@mui/icons-material/Paid";
import { Card, Grid, Typography, Divider, Box, Button } from "@mui/material";

export default function LevelInfo({ level, totalExchanged, balance }) {
  return (
    <Box sx={{ bgcolor: "#e3f2fa", p: 1, py: 1.5, borderRadius: "8px" }}>
      <Grid container spacing={2}>
        {/* <Grid item xs={6}>
          <Typography variant="body1" fontWeight={400}>
            Текущий уровень:
          </Typography>
          <Typography variant="h6" fontWeight={500}>
            {level} уровень
          </Typography>
        </Grid> */}
        <Grid item xs={6}>
          <Typography variant="body1" fontWeight={400}>
            Сумма обменов:
          </Typography>
          <Typography variant="h6" fontWeight={500}>
            {new Intl.NumberFormat("ru-RU").format(totalExchanged).trim()} RUB
          </Typography>
        </Grid>
      </Grid>
      <Typography sx={{mt: 1}} variant="body1" fontWeight={400}>
        Всего начисленно:
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography fontWeight={600} sx={{ fontSize: "24px" }}>
          {balance} RUB
        </Typography>
        {!!balance && (
          <Button
            sx={{ml: 2}}
            component={RouterLink}
            to="/profile/withdraw"
            variant="contained"
            endIcon={<PaidIcon />}
          >
            Вывести
          </Button>
        )}
      </Box>
    </Box>
  );
}
