import convertToRub from "../../../common/convertToRub";
import getPrecision from "../../../common/getPrecision";

export default function getCommissionBTC(
  exchange,
  amountTake,
  amountGive,
  action,
  setCommissionInfo,
  setMinLimit
) {
  const giveCurrency = exchange.currency.give;
  const takeCurrency = exchange.currency.take;

  let isGiveCurrencyBTC =
    giveCurrency.code == "BTC" && giveCurrency.name == "Bitcoin";
  if (!isGiveCurrencyBTC) {
    setCommissionInfo("");
    return 0;
  }

  if (!takeCurrency.BTCFeeEnabled) {
    setCommissionInfo("");
    setMinLimit(exchange.limit.min);
    return 0;
  }

  const setCommission = (range) => {
    let commission = range.sum;
    let oneTake = convertToRub(exchange, 1, "take");
    let precision = exchange.currency.take.precision;
    let fromSum = getPrecision(precision, range.from / oneTake);
    let toSum = getPrecision(precision, range.to / oneTake);
    console.log(fromSum, "fromSum", toSum, "toSum");
    let code = exchange.currency.take.code;
    // let minTake = exchange.limit.min;
    setCommissionInfo(
      `При обмене от <b>${fromSum} ${code}</b> до <b>${toSum} ${code}</b> взымается доп. комиссия <b>${commission} BTC</b>.`
    );
    return commission;
  };

  if (exchange.currency.take.isCash) {
    setMinLimit(null);
    return 0;
  }

  if (exchange.currency.give.code !== "BTC") {
    setCommissionInfo("");
    return 0;
  }

  const ranges = exchange.currency.give.commissionBTC;

  if (action === "take") {
    const takeRub = convertToRub(exchange, amountTake, "take");
    let precision = exchange.currency.take.precision;

    let firstRange = ranges[0];
    if (firstRange) {
      let oneTake = convertToRub(exchange, 1, "take");
      let fromSum = getPrecision(precision, firstRange.from / oneTake);
      let minTake = exchange.limit.min;
      if (fromSum < minTake) {
        setMinLimit(fromSum);
      } else {
        setMinLimit(null);
      }
    } else {
      setMinLimit(null);
    }
    let findRange = ranges.find((r) => takeRub >= r.from && r.to >= takeRub);

    if (findRange) {
      return setCommission(findRange);
    } else {
      setCommissionInfo("");
      return 0;
    }
  } else {
    const giveRub = convertToRub(exchange, amountGive, "give", true);
    let precision = exchange.currency.give.precision;

    let firstRange = ranges[0];
    if (firstRange) {
      let oneTake = convertToRub(exchange, 1, "take");
      let fromSum = getPrecision(precision, firstRange.from / oneTake);
      let minTake = exchange.limit.min;
      if (fromSum < minTake) {
        setMinLimit(fromSum);
      } else {
        setMinLimit(null);
      }
    } else {
      setMinLimit(null);
    }
    let findRange = ranges.find((r) => giveRub >= r.from && r.to >= giveRub);
    if (findRange) {
      return setCommission(findRange);
    } else {
      setCommissionInfo("");
      return 0;
    }
  }
}
