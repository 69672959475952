import axios from "axios";

class Content {
  get() {
    return axios.get("/content");
  }
}

const content = new Content();

export default content;
