import * as React from "react";
import ReceiptTwoToneIcon from "@mui/icons-material/ReceiptTwoTone";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import PaidIcon from "@mui/icons-material/Paid";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Avatar,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Card,
  Divider,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react";
import ProfileStore from "../../store/profile";

const navigationList = [
  {
    name: "Заявки",
    icon: ReceiptTwoToneIcon,
    path: "/profile/orders",
    gradient: "linear-gradient(to right, #f12711, #f5af19)",
  },

  {
    name: "Реквизиты",
    icon: CreditCardOutlinedIcon,
    path: "/profile/cards",
    gradient: "linear-gradient(to right, #1f4037, #99f2c8)",
  },
  {
    name: "Настройки",
    icon: ManageAccountsOutlinedIcon,
    path: "/profile/settings",
    gradient: "linear-gradient(to right, #7f7fd5, #86a8e7, #91eae4)",
  },
  {
    name: "Реферальная программа",
    icon: GroupAddIcon,
    path: "/profile/referral",
    gradient: "linear-gradient(to right, #fdc830, #f37335)",
  },
  {
    name: "Кэшбек",
    icon: CardGiftcardIcon,
    path: "/profile/cashback",
    gradient: "linear-gradient(to right, #fdc830, #f37335)",
  },
  {
    name: "Вывод вознаграждений",
    icon: PaidIcon,
    path: "/profile/withdraw",
    gradient: "linear-gradient(to right, #fdc830, #f37335)",
  },
];

function UserBox({ handleClose }) {
  let profile = ProfileStore.profile;

  if (!profile) return;

  let { name, email } = profile;

  return (
    <Card>
      <Box sx={{ p: 2 }}>
        <Typography
          sx={{ color: "inherit", fontSize: "16px" }}
          variant="body1"
          fontWeight={400}
          align="center"
        >
          {name}
        </Typography>
        <Typography
          sx={{ color: "#475259c4", fontSize: "14px" }}
          variant="body1"
          fontWeight={400}
          align="center"
        >
          {email}
        </Typography>
      </Box>
      <Divider />
      <List component="nav">
        {navigationList.map((n) => {
          return (
            <ListItem
              onClick={() => {
                handleClose();
              }}
              button
              to={n.path}
              component={NavLink}
            >
              <Avatar
                sx={{
                  background: n.gradient,
                  width: "30px",
                  height: "30px",
                  mr: 1,
                }}
              >
                <n.icon fontSize="small" />
              </Avatar>
              <ListItemText primary={n.name} />
            </ListItem>
          );
        })}
        <ListItem
          onClick={() => {
            ProfileStore.logout();
            handleClose();
          }}
          button
        >
          <Avatar
            sx={{
              background: "red",
              width: "30px",
              height: "30px",
              mr: 1,
            }}
          >
            <LogoutIcon fontSize="small" />
          </Avatar>

          <ListItemText primary={"Выйти"} />
        </ListItem>
      </List>
    </Card>
  );
}

export default observer(UserBox);
