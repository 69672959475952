import MAW from "multicoin-address-validator/dist/wallet-address-validator";
import { validate } from "bitcoin-address-validation";

export default function addressValidator(address, code, name) {
  code = code.toLowerCase();

  let valid = MAW.validate(address, name === "Tether TRC20" ? "trx" : code);

  if (code === "btc") {
    valid = validate(address);
  }
  return valid;
}
