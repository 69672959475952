import React, { useEffect, useState } from "react";
import RewardService from "../../services/Reward";
import { Box, CircularProgress, Typography, Grid } from "@mui/material";
import RewardCard from "./RewardCard";
import { EventBus } from "../../event";
import { socket } from "../../socket";

export default function RewardList() {
  let [list, setList] = useState([]);
  let [isLoading, setLoading] = useState(true);

  useEffect(() => {
    RewardService.getReward().then((r) => {
      let data = r.data;
      setList(data);
      setLoading(false);
    });
    EventBus.$on("add_reward", (r) => {
      setList((rewards) => [r, ...rewards]);
    });
    socket.on("update reward", (r) => {
      updateReward(r);
    });
    return () => {
      EventBus.$off("add_reward");
      socket.off("update reward");
    };
  }, []);

  function updateReward(data) {
    setList((list) => {
      let newList = [...list];
      newList = newList.map((r) => (r._id == data._id ? data : r));
      return newList;
    });
  }

  let progressSection = (
    <Box
      sx={{
        my: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
  let listEmptySection = (
    <Typography sx={{ p: 1 }} variant="subtitle1" align="center">
      Вы пока не выводили средства
    </Typography>
  );
  let listSection = (
    <React.Fragment>
      <Typography sx={{ p: 1 }} variant="subtitle1" align="center">
        История вывода средств
      </Typography>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          {list.map((r) => {
            return (
              <Grid key={r._id} xs={12} sm={6} item>
                <RewardCard data={r} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </React.Fragment>
  );

  let view = null;

  if (isLoading) {
    view = progressSection;
  } else {
    if (list.length === 0) {
      view = listEmptySection;
    } else {
      view = listSection;
    }
  }

  return <Box>{view}</Box>;
}
