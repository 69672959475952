import React, { useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Paper,
  Container,
  Modal,
  Grid,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import backImage from "../../assets/images/login-back.png";

export default function Wrap({ children, title, desc, slot, handleClose }) {
  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));

  console.log(largeScreen, "largeScreen");
  return (
    <Box
      sx={{
        minWidth: { md: "700px", xs: "96vw" },
        maxWidth: { md: "700px", xs: "96vw" },
        maxHeight: "90vh",
        overflowY: "auto",
      }}
    >
      <Grid
        container
        direction={largeScreen ? "reverse" : "column-reverse"}
        columns={20}
        alignItems="stretch"
      >
        <Grid item xs={20} md={9}>
          <Box
            style={{ padding: "1px" }}
            sx={{
              minHeight: "100%",
              height: "100%",
              bgcolor: "#00000020",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#00000020",
            }}
          >
            <Box sx={{ my: 2, mx: 1, pb: 2 }}>
              <Typography variant="h5" align="center">
                {title}
              </Typography>
              <Typography variant="body1" align="center">
                {desc}
              </Typography>
              {slot}
            </Box>
            <Box sx={{ maxWidth: "80%", margin: "0px auto", mt: '-30px' }}>
              <img src={backImage} alt="back" width="100%" />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={20} md={11}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1 }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ my: 1, mx: 3 }}>{children}</Box>
        </Grid>
      </Grid>
    </Box>
  );
}
