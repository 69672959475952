import React from "react";
import { observer } from "mobx-react";
import StoreContent from "../../store/content";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';import parse from "html-react-parser";
import {
  Card,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Container,
  Typography,
  Box,
} from "@mui/material";

function FAQPage() {
  const faqList = StoreContent.content.faq;

  return (
    <Container maxWidth="md">
      <Typography
        variant="h3"
        textAlign="center"
        sx={{ mb: 5, mx: 1, fontWeight: 400 }}
      >
        FAQ
      </Typography>
      <Box>
        {faqList.map((f, i) => {
          return (
            <Card key={f._id} sx={{ mb: 2 }}>
              <Accordion>
                <AccordionSummary expandIcon={<KeyboardArrowDownIcon sx={{fontSize: '40px'}} />}>
                  <Typography variant="h5" sx={{ fontWeight: 400 }}>
                    {f.question.rus}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component="div" variant="subtitle1">
                    {parse(f.answer.rus)}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Card>
          );
        })}
      </Box>
    </Container>
    // </>
  );
}

export default observer(FAQPage);
