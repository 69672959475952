import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Popover,
  Divider,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CurrenciesFilter from "../../../components/CurrenciesFilter";

function Nav({
  filterList,
  activeFilter,
  setActiveFilter,
  search,
  setSearch,
  closeHandler,
}) {
  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <CurrenciesFilter
        filterList={filterList}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
      />
      <Box sx={{ flexGrow: 1, ml: 2 }}>
        <TextField
          value={search}
          sx={{fontSize: '20px'}}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Поиск валюты"
          variant="standard"
        />
      </Box>

      <IconButton
        onClick={closeHandler}
        aria-label="Закрыть"
        sx={{ alignSelf: "start" }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
}

export default Nav;
