import axios from "axios";

class Exchange {
  getAll() {
    return axios.get("exchangeV2");
  }
  getOne(params) {
    return axios.get("/one-exchange", { params });
}
}

const exchange = new Exchange();

export default exchange;
