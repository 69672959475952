import OrderService from "../../../../services/Order";
import ProfileStore from "../../../../store/profile";
import { EventBus } from "../../../../event";

export default function submitData(navigate, reqData, setSubmiting) {
  setSubmiting(true);

  OrderService.create(reqData)
    .then((res) => {
      let result = res.data;
      let order = result.order;
      let user = result.user;
      let token = result.token;
      let orderHash = result.hash;
      let isCard = result.card;

      if (user) {
        localStorage.setItem("token", token);
        ProfileStore.setProfile(user);
      }

      if (orderHash) {
        localStorage.setItem("orderHash", orderHash);
      }

   


      if (isCard) {
        navigate("/orders/verify", { state: { data: reqData } });
      } else {
        navigate(`/orders/${order.hash}`);
      }
    })
    .catch((e) => {
      let message = e.response.data.error;
      EventBus.$emit("show_snackbar", { mode: "error", text: message });
    })
    .finally(() => {
      setSubmiting(false);
    });
}
