import React, { useState, useMemo, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Card,
  CircularProgress,
  Button,
} from "@mui/material";
import { observer } from "mobx-react";
import ProfileStore from "../../../store/profile";
import { useForm, Controller } from "react-hook-form";
import luhnValidate from "../../../common/luhnValidate";
import addressValidator from "../../../common/addressValidator";
import NotifySection from "./NotifySection";
import InputMask from "react-input-mask";
import Agreement from "./Agreement";
import submitData from "./methods/submitData";
import { useNavigate } from "react-router-dom";
import QiwiInput from "../../../components/QiwiInput/QiwiInput";
import LogoPng from "../../../assets/logo/logo.png";

function Fields({ exchange, takeAmount, giveAmount, isFetchExchange }) {
  let navigate = useNavigate();

  console.log(exchange, 'Fields exchange')

  const {
    register,
    control,
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  let [takeFields, setTakeFields] = useState([]);
  let [giveFields, setGiveFields] = useState([]);
  let [agree, setAgree] = useState(true);
  let [isSubmitting, setSubmiting] = useState(false);

  let exchangeId = exchange?._id;

  useEffect(() => {
    if (exchange) {
      let takeFieldsList = exchange.currency.take.fields.take;
      let giveFieldsList = exchange.currency.give.fields.give;
      let currTake = exchange.currency.take;
      let currGive = exchange.currency.give;

      setTakeFields(takeFieldsList);
      setGiveFields(giveFieldsList);

      let list = [...takeFieldsList, ...giveFieldsList];

      let qiwiPhone = watch("qiwiPhone");
      let hasQiwi =
        currTake.uniqueType == "qiwi" || currGive.uniqueType == "qiwi";
      let resetData = { email: watch("email") };

      if (qiwiPhone || hasQiwi) {
        resetData["qiwiPhone"] = qiwiPhone ? qiwiPhone : "";
      }
      reset(resetData);
      let newValues = {};
      list.forEach((f) => {
        newValues[f._id] = "";
        setValue(f._id, "");
      });
    }
  }, [exchangeId]);

  const isAuth = ProfileStore.isAuth;
  let emailComponent = !isAuth && (
    <Controller
      name={"email"}
      control={control}
      render={({ field: { onChange, value } }) => (
        <TextField
          error={Boolean(errors.email?.message)}
          {...register("email", {
            required: "Обязательное поле!",
            maxLength: 100,
            pattern: {
              value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: "Некорректный Email!",
            },
          })}
          fullWidth
          margin="normal"
          helperText={errors.email?.message}
          label="Email"
          name="email"
          type="email"
          variant="outlined"
          onChange={onChange}
          value={value}
        />
      )}
    />
  );

  if (!exchange) return null;

  let takeName = exchange.currency.take.name;
  let giveName = exchange.currency.give.name;
  let takeCode = exchange.currency.take.code;
  let giveCode = exchange.currency.give.code;

  function cardNumberValidate(number) {
    let isValid = luhnValidate(number);
    return isValid;
  }

  function coinValidator(value, code, name) {
    let isValid = addressValidator(value, code, name);
    return isValid;
  }
  const onSubmit = handleSubmit((data) => {
    let tf = takeFields.map((f) => {
      return Object.assign({}, f, { value: data[f._id] });
    });
    let gf = giveFields.map((f) => {
      return Object.assign({}, f, { value: data[f._id] });
    });

    let req = {
      email: data.email || null,
      commissionQiwi: 0,
      mobile: data.qiwiPhone ? data.qiwiPhone : null,
      referralLink: ProfileStore.getRef,
      referrer:
        document.referrer.indexOf("localhost") === -1 ? document.referrer : "",
      exchange: exchange._id,
      take: +takeAmount,
      give: +giveAmount,
      fields: {
        take: tf,
        give: gf,
      },
    };
    submitData(navigate, req, setSubmiting);
  });

  return (
    <Card
      sx={{
        p: 1,
        mt: 2,
        position: "relative",
      }}
    >
      {isFetchExchange && (
        <Box
          sx={{
            bgcolor: "#ffffff80",
            zIndex: 100,
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={60} />
        </Box>
      )}

      <Box>
        <form onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box sx={{ p: 1 }}>
                <Typography variant="h6" fontWeight={400}>
                  Реквизиты {takeName}
                </Typography>
                {emailComponent}

                <Box>
                  {exchange.currency.take.uniqueType == "qiwi" &&
                    watch("qiwiPhone") !== undefined && (
                      <QiwiInput
                        error={errors["qiwiPhone"]?.message}
                        name="qiwiPhone"
                        register={register}
                        control={control}
                        style={{ mt: 2 }}
                      />
                    )}
                  {takeFields.map((f) => {
                    let { _id, name, isCard, isAddress } = f;
                    let type = "text";
                    let validate = null;

                    if (isAddress) {
                      validate = (val) =>
                        cardNumberValidate(val) || "Некорректный номер карты";
                    }

                    let textInput = (
                      <Controller
                        key={_id}
                        name={_id}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            {...register(_id, {
                              required: "Обязательное поле!",
                              validate,
                            })}
                            error={Boolean(errors[_id]?.message)}
                            fullWidth
                            margin="normal"
                            helperText={errors[_id]?.message}
                            label={name}
                            name={_id}
                            type={type}
                            variant="outlined"
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    );
                    if (isCard) {
                      textInput = (
                        <Controller
                          key={_id}
                          name={_id}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <InputMask
                              error={Boolean(errors[_id]?.message)}
                              helperText={errors[_id]?.message}
                              {...register(_id, {
                                required: "Обязательное поле!",
                                validate: (val) => {
                                  return (
                                    cardNumberValidate(
                                      val.replace(/\D/g, "")
                                    ) || "Некорректный номер карты"
                                  );
                                },
                              })}
                              fullWidth
                              label={name}
                              margin="normal"
                              maskChar=" "
                              mask="9999 9999 9999 9999 99"
                              value={value}
                              onChange={onChange}
                              name={_id}
                              type="text"
                            >
                              {(e) => <TextField {...e} />}
                            </InputMask>
                          )}
                        />
                      );
                    }

                    return textInput;
                  })}
                </Box>

                <NotifySection exchange={exchange} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ p: 1 }}>
                {" "}
                <Typography variant="h6" fontWeight={400}>
                  Реквизиты {giveName}
                </Typography>
                <Box>
                  {exchange.currency.give.uniqueType == "qiwi" &&
                    watch("qiwiPhone") !== undefined && (
                      <QiwiInput
                        error={errors["qiwiPhone"]?.message}
                        name="qiwiPhone"
                        register={register}
                        control={control}
                        style={{ mt: 2 }}
                      />
                    )}
                  {giveFields.map((f) => {
                    let { _id, name, isCard, isAddress } = f;

                    let type = "text";
                    let validate = null;
                    if (isCard) {
                      type = "number";
                      validate = (val) =>
                        cardNumberValidate(val) || "Некорректный номер карты";
                    }
                    if (isAddress) {
                      validate = (val) =>
                        coinValidator(val, giveCode, giveName) ||
                        `Некорректный адрес ${giveName}`;
                    }

                    return (
                      <Controller
                        key={_id}
                        name={_id}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            {...register(_id, {
                              required: "Обязательное поле!",
                              validate,
                            })}
                            error={Boolean(errors[_id]?.message)}
                            fullWidth
                            margin="normal"
                            helperText={errors[_id]?.message}
                            label={name}
                            name={_id}
                            type={type}
                            variant="outlined"
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    );
                  })}
                </Box>
                <Agreement
                  agree={agree}
                  setAgree={setAgree}
                  style={{ mt: 2 }}
                />
                <Button
                  sx={{
                    mt: 1,
                  }}
                  color="primary"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={!agree || isSubmitting}
                  type="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                >
                  Создать заявку
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Card>
  );
}

export default observer(Fields);
