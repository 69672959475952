import React from "react";
import { Card, Typography, Divider, Box, Grid } from "@mui/material";
import ExchangePair from "./ExchangePair";
import Loader from "./Loader";
import Transaction from "./Transaction";

import getTransactionLink from "./methods/getTransactionLink";

function PaymentConfirm({ order }) {
  let trans = getTransactionLink(order, "take");

  return (
    <Card>
      <Box sx={{ pt: 2, px: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Typography sx={{ mb: 1.5 }} variant="h5" fontWeight="normal">
              Ожидание поступления средств
            </Typography>
            <Typography sx={{ mb: 0.5, color: "#475259" }} variant="body1">
              В данный момент мы проверям поступление средств на наш счёт.
            </Typography>
            <Typography sx={{ mb: 0.5, color: "#475259" }} variant="body1">
              Сразу после того как мы убедимся что средства получены, статус
              заявки будет перевден на следующий этап.
            </Typography>

            {trans && (
              <Transaction
                style={{ mt: 3 }}
                address={trans.address}
                img={order.currency.take.image}
                link={trans.link}
              />
            )}
          </Grid>
          <Grid item xs={12} md={5}>
            <Loader style={{ mt: 2 }}>
              <Typography
                variant="h6"
                textAlign="center"
                fontWeight="semi-bold"
                sx={{ mt: 1.5 }}
              >
                Идёт проверка получения средств...
              </Typography>
              <Typography
                variant="body1"
                textAlign="center"
                fontWeight="normal"
                sx={{ color: "#475259" }}
              >
                В случае возниконовения каких либо вопросов, напишите нам в чат
              </Typography>
            </Loader>
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{ mt: 1 }} />
      <Box sx={{ px: 2, py: 1 }}>
        <Grid alignItems="center" container spacing={2}>
          <Grid item xs={12} md={6}>
            <ExchangePair
              take={{
                value: order.fields.take.find((f) => f.isAddress || f.isCard)
                  ?.value,
                name: order.currency.take.name,
                code: order.currency.take.code,
                img: order.currency.take.image,
                amount: order.amount.take,
              }}
              give={{
                value: order.fields.give.find((f) => f.isAddress || f.isCard)
                  ?.value,
                name: order.currency.give.name,
                code: order.currency.give.code,
                img: order.currency.give.image,
                amount: order.amount.give,
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

export default PaymentConfirm;
