import currenciesFilter from "../data/CurrenciesFilter.json";
function GetFiltersByCurrencies(currencies) {
  const existFilters = {};
  for (let f of currenciesFilter) {
    existFilters[f.id] = false;
  }
  currencies.forEach((c) => {
    existFilters[c.type] = true;
  });
  let result = currenciesFilter.filter((f) => existFilters[f.id]);
  result.unshift({ title: "Все валюты", name: "Все", id: "all" });
  return result;
}

export default GetFiltersByCurrencies;
