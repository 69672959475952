import { Typography, Box } from "@mui/material";

export default function CashbackCard({ data }) {
  return (
    <Box sx={{ bgcolor: "#f0f8ff", borderRadius: 2, p: 1 }}>
      <Typography fontWeight={500} sx={{ color: "#37373c" }} variant="h6">
        Заявка{" "}
        <span style={{ fontFamily: "Arial", fontWeight: 600 }}>
          № {data.order}
        </span>
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body1" sx={{ color: "#37373c" }}>
          {data.sum} RUB
        </Typography>
        <Typography sx={{ color: "#8595a6", fontSize: "14px" }}>
          {data.date}
        </Typography>
      </Box>
    </Box>
  );
}
