import React from "react";
import Container from "@mui/material/Container";
import { Outlet } from "react-router-dom";
import { Grid, Tooltip, Box, Typography, IconButton } from "@mui/material";
import NavBar from "./NavBar";

function ProfileRouter() {
  return (
    <Container maxWidth="md">
      <NavBar />
      <Box sx={{ mt: 2 }}>
        <Outlet />
      </Box>
    </Container>
  );
}
export default ProfileRouter;
