import React from "react";
import { Box, Typography } from "@mui/material";
import getRateText from "./getRateText";

function CurrencyInfo({
  newMin = null,
  side = "take",
  exchange,
  city = "",
  commissionInfo,
}) {
  let min = newMin || exchange.limit.min;
  let max = exchange.limit.max;
  let takeCode = exchange.currency.take.code;
  let giveCode = exchange.currency.give.code;
  let reserve = exchange.currency.give.MReserve;
  let rateText = getRateText(exchange);

  let takeText = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography sx={{ mr: 1 }} variant="body1" fontWeight="normal">
        Мин:{" "}
        <Typography
          component="span"
          variant="body1"
          fontWeight={500}
        >{`${min} ${takeCode}`}</Typography>
      </Typography>
      <Typography variant="body1" fontWeight="normal">
        Мaкc:{" "}
        <Typography
          component="span"
          variant="body1"
          fontWeight={500}
        >{`${max} ${takeCode}`}</Typography>
      </Typography>
    </Box>
  );
  let giveText = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography sx={{ mr: 1 }} variant="body1" fontWeight="normal">
        Курс:{" "}
        <Typography component="span" variant="body1" fontWeight={500}>
          {rateText}
        </Typography>
      </Typography>
      <Typography variant="body1" fontWeight="normal">
        Резерв:{" "}
        <Typography component="span" variant="body1" fontWeight={500}>
          {`${reserve} ${giveCode}`}
        </Typography>
      </Typography>
    </Box>
  );

  return (
    <Box
      sx={{
        p: 0.5,
      }}
    >
      {side == "take" ? takeText : giveText}

      {commissionInfo && (
        <Typography
          variant="body1"
          fontWeight={400}
          dangerouslySetInnerHTML={{ __html: commissionInfo }}
          sx={{ mt: 0.5 }}
        />
      )}
      {city && (
        <Typography sx={{ mt: 0.5 }} variant="body1" fontWeight={400}>
          Город: <b style={{ fontWeight: 500 }}>{city}</b>
        </Typography>
      )}
    </Box>
  );
}

export default CurrencyInfo;
