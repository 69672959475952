import React from "react";
import {
  Container,
  Card,
  Typography,
  Divider,
  TextField,
  Box,
  Grid,
  Avatar,
  styled,
  CircularProgress,
} from "@mui/material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import DoneIcon from "@mui/icons-material/Done";

function StatusCardIndicator({ status, banned = false, comment = "" }) {
  function Success() {
    return (
      <>
        <DoneIcon sx={{ fontSize: 60, color: "limegreen" }} />
        <Typography
          variant="h5"
          sx={{ mt: 1.5 }}
          fontWeight={500}
          textAlign="center"
        >
          Верификация пройдена
        </Typography>
      </>
    );
  }

  function Rejected() {
    let banSubtitle = (
      <Typography variant="subtitle1" sx={{ mt: 0.5 }} textAlign="center">
        К сожалению, мы не можем обслужить <b>вашу</b> карту. <br />
        Попробуйте обратится в другой обменный сервис.
      </Typography>
    );
    let rejSubtitle = (
      <>
        <Typography variant="subtitle1" sx={{ mt: 0.5 }} textAlign="center">
          Пожалуйста, загрузите фото повторно
        </Typography>
        <Typography variant="subtitle1" sx={{ mt: 0.5 }} textAlign="center">
          Причина:
        </Typography>
        <Typography
          variant="subtitle2"
          fontWeight="semibold"
          sx={{ color: "red" }}
          textAlign="center"
        >
          {comment}
        </Typography>
      </>
    );

    return (
      <>
        <NotInterestedIcon sx={{ fontSize: 60, color: "red" }} />
        <Typography
          variant="h5"
          sx={{ mt: 1.5 }}
          fontWeight={500}
          textAlign="center"
        >
          Карта отклонена
        </Typography>
        {banned ? banSubtitle : rejSubtitle}
      </>
    );
  }

  function Process() {
    return (
      <>
        <CircularProgress size={60} color="primary" />
        <Typography
          variant="h5"
          sx={{ mt: 1.5 }}
          fontWeight={500}
          textAlign="center"
        >
          Карта в обработке...
        </Typography>
        <Typography variant="subtitle1" sx={{ mt: 0.5 }} textAlign="center">
          В данный момент мы сверяем данные на соответствие
        </Typography>
      </>
    );
  }

  function view() {
    switch (status) {
      case "resolved":
        return <Success />;
      case "rejected":
        return <Rejected />;
      case "process":
        return <Process />;
    }
  }

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {view()}
    </Box>
  );
}

export default StatusCardIndicator;
