import React, { useState, useEffect, useMemo } from "react";
import { useCountdown } from "./methods/countDown";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Card,
  Typography,
  Divider,
  Box,
  Button,
} from "@mui/material";
import Payment from "./Payment";
import Resolved from "./Resolved";
import Rejected from "./Rejected";
import PaymentReceived from "./PaymentReceived";
import PaymentConfirm from "./PaymentConfirm";
import CardProcess from "../../components/Card/Process";
import CardRejected from "../../components/Card/Rejected";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import OrderService from "../../services/Order";
import VerifyCard from "./VerifyCard";
import getOrderStatusText from "./methods/getOrderStatusText";
import { socket } from "../../socket";
import { observer } from "mobx-react";
import ProfileStore from "../../store/profile";
import { NavLink } from "react-router-dom";

let intervalId;

function Order() {
  let [verifyData, setVerifyData] = useState(null);
  let navigate = useNavigate();
  let [order, setOrder] = useState(null);
  let [isFinished, setIsFinished] = useState(false);
  let { id } = useParams();
  const location = useLocation();

  let isAuth = ProfileStore.isAuth;

  useEffect(() => {
    let isVerify = id == "verify";
    if (isVerify) {
      let createData = location.state && location.state.data;
      if (!createData) {
        navigate("/");
      } else {
        setVerifyData(createData);
        window.history.replaceState({}, document.title);
      }


    }

    if (!isVerify) {
      let hash = id;
      OrderService.getOne(hash)
        .then((res) => {
          let data = res.data;
          setOrder(data);
        
          socket.emit("auth_order", hash);
          socket.on(`hash_update_order_${data._id}`, updateOrder);
        })
        .catch((e) => {
          console.error(e);
        });
    }

    return () => {
      setOrder(null);
      if (id && id !== "verify") {
        socket.emit("auth_order_leave", id);
        socket.off(`hash_update_order_${id}`);
      }
    };
  }, [id]);

  function updateOrder(o) {
    setOrder((order) => {
      if (order._id == o._id) {
        return o;
      } else {
        return order;
      }
    });
  }

  useEffect(() => {
    if (order) {
      intervalId = setInterval(() => {
        if (order) {
          let diff = new Date(order.finished) - Date.now();
          if (diff <= 0 && !isFinished) {
            setIsFinished(true);
          }
        }
      }, 1000);
    } else {
      clearInterval(intervalId);
    }
  }, [order]);

  let statusText = useMemo(
    () => getOrderStatusText(order, id, isFinished),
    [order, isFinished]
  );

  function ViewOrderStep() {
    if (!verifyData && statusText == "verify") return;
    switch (statusText) {
      case "rejectedCard":
        return <CardRejected data={order.card} />;
      case "cardProcessing":
        return <CardProcess data={order.card} />;
      case "verify":
        return <VerifyCard data={verifyData} />;
      case "rejected":
        return <Rejected order={order} />;
      case "resolved":
        return <Resolved order={order} />;
      case "paymentReceived":
        return <PaymentReceived order={order} />;
      case "paymentConfirm":
        return <PaymentConfirm order={order} />;
      case "payment":
        return <Payment order={order} />;
      default:
        return null;
    }
  }

  if (!(verifyData || order)) return null;

  return (
    <Container maxWidth="md">
      {isAuth && (
        <Button
          sx={{ mb: 2, color: "#475259c4" }}
          startIcon={<ArrowBackIosIcon />}
          component={NavLink}
          to="/profile/orders"
        >
          Заявки
        </Button>
      )}

      <Box>
        <Box>
          <ViewOrderStep />
        </Box>
      </Box>
    </Container>
  );
}

export default observer(Order);
