import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Grid,
  Tooltip,
  TextField,
  styled,
  Avatar,
  Typography,
  CardActionArea,
  Alert,
  Divider,
  CircularProgress,
  Box,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  alpha,
  Dialog,
} from "@mui/material";
import InputMask from "react-input-mask";
import QiwiInput from "../QiwiInput/QiwiInput";
import axios from "axios";
import { EventBus } from "../../event";

import RewardService from "../../services/Reward";
import CurrencyCard from "../CurrencyCard/CurrencyCard";
import { useForm, Controller } from "react-hook-form";
import luhnValidate from "../../common/luhnValidate";

import CloseIcon from "@mui/icons-material/Close";

function getModeText(mode) {
  switch (mode) {
    case "referral":
      return "Реферальная система";
    case "cashback":
      return "Кэшбэк";
    default:
      return "";
  }
}

function cardNumberValidate(number) {
  let isValid = luhnValidate(number);
  return isValid;
}

export default function CashoutWindow(props) {
  const {
    onClose,
    open,
    mode,
    referralBalance,
    cashbackBalance,
    closeHandler,
    addReward,
  } = props;
  let [currencies, setCurrencies] = useState([]);
  let [selectedCurrency, setSelectedCurrency] = useState(null);
  let [fields, setFields] = useState([]);
  let [alert, setAlert] = useState("");
  let [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    RewardService.getCurrencies().then((res) => {
      let list = res.data;
      setCurrencies(list);
      setSelectedCurrency(list[0]);
    });
  }, []);

  const {
    register,
    control,
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      amount: 0,
    },
  });

  function changeSelectedCurrency(e) {
    let curr = currencies.find((c) => c._id + "" == e + "");
    setSelectedCurrency(curr);
  }

  let selectedCurrencyId = selectedCurrency && selectedCurrency._id;

  useEffect(() => {
    if (selectedCurrency) {
      setAlert("");
      reset({ amount: watch("amount") });
      let fs = selectedCurrency.fields.take;
      fs.forEach((f) => {
        setValue(f._id, "");
      });
      if (selectedCurrency.uniqueType == "qiwi") {
        setValue("qiwiPhone", "");
      }
      setFields(fs);
    }
  }, [selectedCurrencyId]);

  let modeText = getModeText(mode);

  const onSubmit = handleSubmit((data) => {
    setSubmitting(true);
    let fields = selectedCurrency.fields.take.map((f) => {
      return Object.assign({}, f, {
        value: f.isCard ? data[f._id].replace(/\D/g, "") : data[f._id],
      });
    });

    axios
      .post("/reward", {
        sum: +data.amount,
        type: mode,
        currencyId: selectedCurrencyId,
        fields,
        mobileNumber:
          selectedCurrency.uniqueType == "qiwi" ? data.qiwiPhone : null,
      })
      .then((response) => {
        EventBus.$emit("show_snackbar", {
          text: "Заявка на вывод средств создана!",
          mode: "success",
        });
        let r = response.data;
        EventBus.$emit("add_reward", r);
        closeHandler();
      })
      .catch((err) => {
        console.log(err, "er");
        if (err.response.data.has) {
          setAlert(err.response.data.error);
        } else {
          console.error(err);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  });

  function validateAmount(amount) {
    let maxAmount = 400;
    if (mode == "referral") {
      maxAmount = +referralBalance;
    } else if (mode == "cashback") {
      maxAmount = +cashbackBalance;
    }

    if (amount < 100) {
      return "Некорректное значение";
    } else if (amount > maxAmount) {
      return `Макс. сумма ${maxAmount} RUB`;
    }
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          p: 1.5,
          minWidth: {
            xs: "90%",
            sm: "450px",
          },
          maxWidth: {
            xs: "90%",
            sm: "450px",
          },
        },
      }}
      onClose={onClose}
      open={open}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body1" fontWeight={400}>
            Вывод вознаграждения: <b>{modeText}</b>
          </Typography>
          <IconButton sx={{ ml: 2 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box>
          <Alert sx={{ my: 1 }} icon={false} severity="info">
            Минимальная сумма вывода 100 RUB
          </Alert>
          <form onSubmit={onSubmit}>
            {alert && (
              <Alert variant="filled" severity="error">
                {alert}
              </Alert>
            )}

            <FormControl sx={{ my: 1 }} fullWidth>
              <InputLabel>Валюта</InputLabel>
              <Select
                value={selectedCurrencyId + ""}
                label="Валюта"
                onChange={(e) => changeSelectedCurrency(e.target.value + "")}
              >
                {currencies.map((c) => {
                  return (
                    <MenuItem value={c._id}>
                      <CurrencyCard img={c.image} name={c.name} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Controller
              name={"amount"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  error={Boolean(errors.amount?.message)}
                  {...register("amount", {
                    required: true,
                    validate: (val) => validateAmount(val),
                  })}
                  fullWidth
                  margin="normal"
                  max="100"
                  helperText={errors.amount?.message}
                  label="Сумма"
                  name="amount"
                  type="number"
                  variant="outlined"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            {selectedCurrency &&
              selectedCurrency.uniqueType == "qiwi" &&
              watch("qiwiPhone") !== undefined && (
                <QiwiInput
                  error={errors["qiwiPhone"]?.message}
                  name="qiwiPhone"
                  register={register}
                  control={control}
                  style={{ mt: 2 }}
                />
              )}
            <Box>
              {fields.map((f) => {
                let { _id, name, isCard } = f;
                let type = "text";
                let validate = null;
                let textInput = (
                  <Controller
                    key={_id}
                    name={_id}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        {...register(_id, {
                          required: "Обязательное поле!",
                          validate,
                        })}
                        error={Boolean(errors[_id]?.message)}
                        fullWidth
                        margin="normal"
                        helperText={errors[_id]?.message}
                        label={name}
                        name={_id}
                        type={type}
                        variant="outlined"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                );
                if (isCard) {
                  textInput = (
                    <Controller
                      key={_id}
                      name={_id}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputMask
                          error={Boolean(errors[_id]?.message)}
                          helperText={errors[_id]?.message}
                          {...register(_id, {
                            required: "Обязательное поле!",
                            validate: (val) => {
                              return (
                                cardNumberValidate(val.replace(/\D/g, "")) ||
                                "Некорректный номер карты"
                              );
                            },
                          })}
                          fullWidth
                          label={name}
                          margin="normal"
                          maskChar=" "
                          mask="9999-9999-9999-9999 99"
                          value={value}
                          onChange={onChange}
                          name={_id}
                          type="text"
                        >
                          {(e) => <TextField {...e} />}
                        </InputMask>
                      )}
                    />
                  );
                }
                return textInput;
              })}
            </Box>
            <Button
              sx={{
                mt: 1,
              }}
              color="primary"
              startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmitting}
              type="submit"
              fullWidth
              size="large"
              variant="contained"
            >
              Отправить
            </Button>
          </form>
        </Box>
      </Box>
    </Dialog>
  );
}
