import React from "react";
import { Card, Grid, Typography, Divider, Box, Button } from "@mui/material";
import History from "./Hiistory";
import ProfileStore from "../../store/profile";
import backImg from "../../assets/images/cashback-back.png";
import levelData from "./levelData.json";
import LevelCard from "./LevelCard";
import LevelInfo from "./LevelInfo";

function getAccrualHistory(profile) {
  if (profile.accrual && profile.accrual.history) {
    let l = profile.accrual.history.filter((i) => i.sum !== 0);
    return l;
  } else {
    return [];
  }
}

function getAccrualBalance(profile) {
  if (!profile.accrual) {
    return 0;
  } else {
    return profile.accrual.balance;
  }
}

function Cashback() {
  let isVerified = ProfileStore.isVerified;

  let profileObj = ProfileStore.profile;

  let accrualHistoryList = getAccrualHistory(profileObj);
  let accrualBalance = getAccrualBalance(profileObj);

  if (!isVerified)
    return (
      <Card sx={{ p: 1 }}>
        <Typography variant="subtitle1" align="center">
          Для того чтобы воспользоваться <b>Кэшбэком</b> вам нужно активировать
          аккаунт по Email!
        </Typography>
      </Card>
    );

  return (
    <Card>
      <Typography sx={{ p: 2, pb: 1 }} variant="h5" fontWeight="normal">
        Кэшбэк программа
      </Typography>
      <Grid container spacing={2} sx={{ p: 2, pt: 0 }}>
        <Grid item xs={12} sm={8}>
          <Typography sx={{mb: 3}} variant="body1" fontWeight={400}>
            Проводите обмены, и получайте кэшбэк-вознаграждение до 0.1% от сумму заявки, за каждую
            успешную сделку! А после выводите деньги на любой доступный Вам
            счет.
          </Typography>
          {/* <Typography variant="body1" paragraph sx={{ mt: 1 }} fontWeight={400}>
            Для того чтобы получить больше вознаграждений, повышайте Ваш уровень
            увеличивая общую сумму обменов.
          </Typography> */}
          {/* <Typography variant="body1" paragraph sx={{ mt: 1 }} fontWeight={400}>
            Таким образом вы можете получить кэшбэк до <b>0.15</b>% от
            выполненной сделки!
          </Typography> */}
          {/* <Typography
            variant="body2"
            sx={{ mb: 1, color: "#475259" }}
            fontWeight={300}
          >
            *c уровнями, и суммами обмена для их получения можете ознакомиться
            ниже
          </Typography> */}
          <LevelInfo
            level={profileObj.level}
            totalExchanged={profileObj.totalExchanged}
            balance={accrualBalance}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box sx={{ width: "100%" }}>
            <img src={backImg} alt="Кэшбэк" width="100%" />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ px: 2, py: 1 }}>
        {/* <Typography
          sx={{ mb: 1 }}
          variant="h5"
          align="center"
          fontWeight="normal"
        >
          Уровни
        </Typography> */}
        {/* <Grid
          direction="row"
          justifyContent="center"
          alignItems="center"
          container
          columns={12}
          spacing={2}
        >
          {levelData.map((l) => (
            <Grid key={l.num} item xs={12} md={3}>
              <LevelCard {...l} />
            </Grid>
          ))}
        </Grid> */}
      </Box>

      <Divider />
      <History list={accrualHistoryList} />
    </Card>
  );
}

export default Cashback;
