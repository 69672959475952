import React, { useState } from "react";
import { observer } from "mobx-react";
import ProfileStore from "../../store/profile";
import RewardList from "./RewardList";
import { Card, Grid, Typography, Divider, Box } from "@mui/material";
import BalanceCard from "./BalanceCard";
import CashoutWindow from "./CashoutWindow";

function getReferralBalance(profile) {
  if (!profile.referral) {
    return 0;
  } else {
    return profile.referral.balance;
  }
}
function getAccrualBalance(profile) {
  if (!profile.accrual) {
    return 0;
  } else {
    return profile.accrual.balance;
  }
}

function Withdraw() {
  let [showCashout, setCashout] = useState("");

  let isVerified = ProfileStore.isVerified;
  let profileObj = ProfileStore.profile;
  function cashoutCloseHandler() {
    setCashout("");
  }

  let referralBalance = +getReferralBalance(profileObj);
  let accrualBalance = getAccrualBalance(profileObj);

  if (!isVerified)
    return (
      <Card sx={{ p: 1 }}>
        <Typography variant="subtitle1" align="center">
          Для того чтобы воспользоваться <b>Вывод начислений</b> вам нужно
          активировать аккаунт по Email!
        </Typography>
      </Card>
    );

  return (
    <Card>
      <Typography sx={{ p: 2, py: 1 }} variant="h5" fontWeight="normal">
        Вывод вознаграждений
      </Typography>
      <Box sx={{ px: 2, py: 1 }}>
        <Box
          sx={{
            borderRadius: 1.5,
            bgcolor: "#d3d3d36e",
            p: 1,
            px: 2,
            width: "max-content",
          }}
        >
          <Typography variant="h6" fontWeight="normal">
            Всего начислено:
          </Typography>
          <Typography variant="h6" fontWeight={600}>
            {new Intl.NumberFormat("ru-RU")
              .format(Math.floor(+referralBalance + +accrualBalance))
              .trim()} RUB
          </Typography>
        </Box>

        <CashoutWindow
          addReward={() => {}}
          closeHandler={() => {
            setCashout("");
          }}
          referralBalance={+referralBalance}
          cashbackBalance={+accrualBalance}
          mode={showCashout}
          open={!!showCashout}
          onClose={cashoutCloseHandler}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <BalanceCard
              title="Реферальные начисления"
              balance={referralBalance}
              openHandler={() => setCashout("referral")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <BalanceCard
              title="Кэшбэк бонусы"
              balance={accrualBalance}
              openHandler={() => setCashout("cashback")}
            />
          </Grid>
        </Grid>
      </Box>

      <Divider />
      <RewardList />
    </Card>
  );
}

export default observer(Withdraw);
