export default function getOrderStatusText(order, id, isFinished) {
  if (id === "verify" || id === "change" || id === "direction") return id;

  if (!order) return "";

  console.log(id, "id");

  let status = order.status;
  let card = order.card;

  if (card && card.status === 103) {
    if (isFinished) {
      return "rejected";
    } else {
      return "rejectedCard";
    }
  } else if (status === 101 && card && card.status === 101) {
    return "cardProcessing";
  } else if (status === 102) {
    return "payment";
  } else if (status === 103) {
    return "paymentConfirm";
  } else if (status === 104) {
    return "paymentReceived";
  } else if (status === 106) {
    return "rejected";
  } else if (status === 105) {
    return "resolved";
  } else {
    return "";
  }
}
