import React, { useState, useEffect } from "react";
import OrderCard from "./OrderCard";
import OrderService from "../../services/Order";
import { Link } from "react-router-dom";
import { socket } from "../../socket";
import { Grid, Box, Typography, Card, CircularProgress } from "@mui/material";
import { EventBus } from "../../event";

function OrdersList() {
  let [orders, setOrders] = useState([]);
  let [isLoading, setLoading] = useState(true);

  useEffect(() => {
    EventBus.$emit("show_progress_bar", true);
    OrderService.getAll()
      .then((res) => {
        let data = res.data;
        setOrders(data);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
        EventBus.$emit("show_progress_bar", false);
      });
    socket.on("add order", (o) => {
      setOrders((orders) => [o, ...orders]);
    });
    socket.on("update order", (o) => updateOrder(o));

    return () => {
      setOrders([]);
      socket.off("add order");
      socket.off("update order");
    };
  }, []);

  function updateOrder(order) {
    setOrders((orders) => {
      let newOrders = orders.map((item) => {
        if (item._id == order._id) {
          return order;
        } else {
          return item;
        }
      });
      return newOrders;
    });
  }

  if (isLoading)
    return (
      <Box
        sx={{
          my: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box>
      {!orders.length ? (
        <Card sx={{ p: 1 }}>
          <Typography gutterBottom variant="subtitle1" align="center">
            Вы ещё не совершили ни одного обмена
          </Typography>
        </Card>
      ) : (
        <Grid container spacing={2}>
          {orders.map((o) => (
            <Grid item xs={12} md={6}>
              <Link
                style={{ textDecoration: "none" }}
                key={o._id}
                to={`/orders/${o.hash}`}
              >
                <OrderCard data={o} />
              </Link>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}

export default OrdersList;
