import React from "react";
import {
  Card,
  CardContent,
  CardActionArea,
  Box,
  styled,
  Chip,
  useTheme,
  Typography,
} from "@mui/material";
import moment from "moment";
import "moment/locale/ru";
import ccFormat from "../../common/ccFormat";
import getCardStatusText from "../../common/getCardStatusText";
import getCardStatusName from "../../common/getCardStatusName";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Badge from "../Badge";

function CardItem({ data }) {
  let { name, number, created, status } = data;

  let statusText = getCardStatusText(status);
  let statusName = getCardStatusName(statusText);
  var labelStatus = (function () {
    switch (status) {
      case 101:
        return "yellow";
      case 102:
        return "green";
      case 103:
      case 104:
        return "red";
      default:
        return "";
    }
  })();

  var icon = (function () {
    switch (status) {
      case 102:
        return (
          <CheckCircleOutlineIcon
            sx={{ color: "white", "&&": { color: "white" } }}
          />
        );
      case 103:
      case 104:
        return (
          <ErrorOutlineIcon sx={{ "&&": { color: "white" }, color: "white" }} />
        );
      case 101:
        return (
          <HourglassBottomIcon
            sx={{ "&&": { color: "white" }, color: "white" }}
          />
        );
      default:
        return "";
    }
  })();

  var gradientColor = (function () {
    switch (status) {
      case 102:
        return "rgb(3,255,19)";
      case 103:
      case 104:
        return "rgb(255,3,3)";
      case 101:
        return "rgb(241,255,3)";
      default:
        return "";
    }
  })();

  return (
    <Card>
      <CardActionArea sx={{ py: 1.5, px: 1, "text-decoration": "none" }}>
        <Typography variant="h6" fontWeight="normal">
          {ccFormat(number)}
        </Typography>
        <Typography variant="body1" sx={{ mt: 1, color: '#475259' }}>
          {name}
        </Typography>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: "#475259c4" }} variant="body2" noWrap>
            {moment(new Date(created)).format("D MMM YYYY, h:mm")}
          </Typography>

          <Badge color={labelStatus} name={statusName} />
        </Box>
      </CardActionArea>
    </Card>
  );
}

export default CardItem;
