import React from "react";
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import {
  Box,
  TextField,
  CircularProgress,
  Button,
  IconButton,
  InputAdornment,
  Typography,
  Avatar,
} from "@mui/material";
import phoneValidator from "./phoneValidator";
import QiwiIcon from "../../assets/images/countries/qiwi.png";
export default function QiwiInput(props) {
  let { name, control, error, register, style = {} } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        let phoneFormated = value.replace(/\D/g, "");
        let phoneValidatorResult = phoneValidator(phoneFormated);
        return (
          <InputMask
            sx={style}
            error={Boolean(error)}
            helperText={error}
            {...register(name, {
              required: "Номер телефона не может быть пустым",
              validate: (val) => {
                let ph = val.replace(/\D/g, "");
                let rval = ph.length >= 11 || "Некорректный номер телефона";
                return rval;
              },
            })}
            fullWidth
            label="Номер телефона Qiwi"
            mask="+9 (999) 999-99-99"
            value={phoneValidatorResult.value}
            onChange={onChange}
            name="phone"
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Avatar
                    alt={phoneValidatorResult.alt}
                    src={phoneValidatorResult.img || QiwiIcon}
                    variant="square"
                    sx={{ width: 30, height: 30 }}
                  />
                </InputAdornment>
              ),
            }}
          >
            {(e) => <TextField {...e} />}
          </InputMask>
        );
      }}
    />
  );
}
