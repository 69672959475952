import { useState, useEffect, useMemo } from "react";
import TakeCurrencies from "./methods/takeCurrencies";
import GiveCurrencies from "./methods/giveCurrencies";
import initTakeSelectedCurrency from "./methods/initTakeSelectedCurrency";
import initGiveSelectedCurrency from "./methods/initGiveSelectedCurrency";

const SelectedCurrencies = (props) => {
  let { exchanges, urlCurr, setUrlCurr } = props;
  const [takeSelectedCurrency, setTakeSelectedCurrency] = useState(null);
  const [giveSelectedCurrency, setGiveSelectedCurrency] = useState(null);

  let takeCurrencies = useMemo(() => TakeCurrencies(exchanges), [exchanges]);
  let giveCurrencies = useMemo(
    () => GiveCurrencies(exchanges, takeSelectedCurrency),
    [takeSelectedCurrency]
  );

  if (takeCurrencies.length !== 0 && !takeSelectedCurrency) {
    initTakeSelectedCurrency(
      exchanges,
      takeCurrencies,
      takeSelectedCurrency,
      setTakeSelectedCurrency,
      urlCurr
    );
  }

  useEffect(() => {
    initGiveSelectedCurrency(
      exchanges,
      giveCurrencies,
      giveSelectedCurrency,
      setGiveSelectedCurrency,
      urlCurr,
      setUrlCurr
    );
  }, [giveCurrencies]);

  return {
    takeCurrencies,
    giveCurrencies,
    takeSelectedCurrency,
    setTakeSelectedCurrency,
    giveSelectedCurrency,
    setGiveSelectedCurrency
  };
};

export default SelectedCurrencies;
