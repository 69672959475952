import config from "./config";
import io from "socket.io-client";

let socket;

function connect() {
  socket = io(config.SERVER, {
    transports: ["websocket","polling", "flashsocket"],
    reconnection: true,
    // secure: false
  });

  socket.on("connect", function () {
    console.log("connect fired!");
  });
 
  socket.on("disconnect", function () {
    console.log("disconnect fired!");
  });
  socket.on("reconnect", function () {
    console.log("reconnect fired!");
  });
  socket.on('connect_error', (error) => {
    console.error(error);
    console.error(`Connection error: ${error.message}`);
  });
}

function authenticate() {
  const token = localStorage.getItem("token");
  if (token) {
    console.log("authenticate");
    socket.emit("authenticate", token);
  }
}

export { authenticate, connect, socket };

export default socket;
