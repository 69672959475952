import { useState, useEffect } from "react";

import calculateTake from "./methods/calculateTake";
import calculateGive from "./methods/calculateGive";
import validateAmountsOnBlur from "./methods/validateAmountsOnBlur";

let amountErrorTimerId;


const CalculateAmount = ({ exchange }) => {
  let [takeAmount, setTakeAmount] = useState(0);
  let [giveAmount, setGiveAmount] = useState(0);
  let [lastInput, setLastInput] = useState("take");
  let [commissionInfo, setCommissionInfo] = useState("");
  let [newMin, setNewMin] = useState("");
  let [amountError, setAmountError] = useState(null);

  function changeAmount(side, amount) {
    if (side == "take") {
      setTakeAmount(amount);
      calculateGive(
        exchange,
        +amount,
        setGiveAmount,
        setCommissionInfo,
        setNewMin
      );
      setLastInput("take");
    } else if (side == "give") {
      setGiveAmount(amount);
      calculateTake(
        exchange,
        +amount,
        setTakeAmount,
        setCommissionInfo,
        setNewMin
      );
      setLastInput("give");
    }
  }
  function changeTakeAmount(amount) {
    changeAmount("take", amount);
  }
  function changeGiveAmount(amount) {
    changeAmount("give", amount);
  }

  function updateRate() {
    // if(takeAmount)
    if (!exchange) return;

    if (lastInput == "take") {
      calculateGive(
        exchange,
        +takeAmount,
        setGiveAmount,
        setCommissionInfo,
        setNewMin
      );
    } else {
      calculateTake(
        exchange,
        +giveAmount,
        setTakeAmount,
        setCommissionInfo,
        setNewMin
      );
    }
    validateAmountHandler(lastInput);
  }

  function changeAmountErrorHandler(data) {
    clearTimeout(amountErrorTimerId);
    setAmountError(data);
    amountErrorTimerId = setTimeout(() => setAmountError(null), 2000);
  }

  function validateAmountHandler(side) {
    let reserve = exchange.currency.give.MReserve;
    let minTake = newMin || exchange.limit.min;
    validateAmountsOnBlur(
      side,
      +takeAmount,
      +giveAmount,
      changeAmount,
      changeAmountErrorHandler,
      reserve,
      minTake,
      exchange.limit.max,
      exchange.currency.take.code,
      exchange.currency.give.code
    );
  }

  let updateService = exchange && exchange.rate;

  useEffect(() => {
    updateRate();
  }, [updateService]);


  return {
    takeAmount,
    giveAmount,
    changeAmount,
    changeTakeAmount,
    changeGiveAmount,
    lastInput,
    commissionInfo,
    newMin,
    amountError,
    validateAmountHandler
  }
};

export default CalculateAmount;
