import axios from "axios";

class RewardService {
  getReward() {
    return axios.get("/reward");
  }
  getCurrencies() {
    return axios.get("/reward-currencies");
  }
}

const rewardService = new RewardService();

export default rewardService;
