import React from "react";
import { Typography, Box } from "@mui/material";

function Info({ title, style = {} }) {
  return (
    <Box sx={{ ...style }}>
      <Typography sx={{ mb: 1 }} variant="h5" fontWeight="normal">
        {title}
      </Typography>
      <Typography sx={{ mb: 0.5, color: "#475259" }} variant="body1">
        Верификация карты — это мера безопасности, для защиты от мошеннических
        действий. Благодаря ей мы можем убедиться в том, что именно Вы являетесь
        владельцем карты.
      </Typography>
      <Typography variant="body1" sx={{ color: "#475259" }}>
        Для успешной верификации необходимо сделать фотографию в соответсвии
        ниже описанными требованиями.
      </Typography>
    </Box>
  );
}

export default Info;
