import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Tooltip,
  TextField,
  styled,
  Button,
  Chip,
  Avatar,
  Typography,
  CardActionArea,
  Divider,
  Box,
  Link,
  alpha,
} from "@mui/material";
import moment from "moment";
import "moment/locale/ru";
import Badge from "../Badge";

moment.locale("ru");

function getStatusText(reward) {
  switch (reward.status) {
    case 101:
      return "В обработке";
    case 102:
      return "Выплачена";
    case 103:
      return "Отклоненна";
    default:
      return "";
  }
}

function getBgColor(reward) {
  switch (reward.status) {
    case 102:
      return "green";
    case 103:
      return "red";
    case 101:
      return "yellow";
    default:
      return "";
  }
}

function getTypeText(reward) {
  switch (reward.type) {
    case "cashback":
      return "Кэшбэк";
    case "referral":
      return "Реферальная программа";
    default:
      return "";
  }
}

export default function RewardCard({ data }) {
  let statusText = getStatusText(data);
  let bgcolor = getBgColor(data);
  let typeText = getTypeText(data);

  return (
    <Box
      sx={{
        bgcolor: "#f0f8ff",
        borderRadius: 2,
        p: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Typography fontWeight={500} sx={{ color: "#37373c" }} variant="h6">
          {new Intl.NumberFormat("ru-RU").format(data.sum).trim()} RUB
        </Typography>
        <Badge name={statusText} color={bgcolor} />
      </Box>
      {data.fields.map((f) => {
        return (
          <Box key={f._id} sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2">{f.name}:</Typography>
            <Typography sx={{ color: "#37373c", ml: 1 }} variant="body2">
              {f.value}
            </Typography>
          </Box>
        );
      })}

      {data.status === 103 && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1">Комментарий сервиса:</Typography>
          <Typography variant="body1" sx={{ color: "red", ml: 1 }}>
            {data.comment}
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 1,
        }}
      >
        <Typography
          fontWeight={400}
          sx={{ color: "#8595a6", fontSize: "14px" }}
        >
          {moment(data.created).format("D MMM YYYY, h:mm")}
        </Typography>
        <Typography sx={{ color: "#37373c" }} variant="body1">
          {typeText}
        </Typography>
      </Box>
    </Box>
  );
}
