import * as React from "react";
import { Box, Typography, Container } from "@mui/material";
import { NavLink } from "react-router-dom";
import bestch from "../assets/images/best.png";

let navLinks = [
  {
    to: "/reserves",
    name: "Резервы",
  },
  {
    to: "/agreement",
    name: "Соглашение",
  },
  {
    to: "/contacts",
    name: "Контакты",
  },
];

export default function AppFooter() {
  return (
    <Box
      sx={{
        p: 1,
        bgcolor: "#3bd2a287",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          <Typography>
            © {new Date().getFullYear()}, Menyatt.com - Безопасный и быстрый обмен криптовалют
          </Typography>
          <Box>
            {navLinks.map((l) => {
              return (
                <Typography
                  sx={{ mr: 2, color: "#475259c4" }}
                  component={NavLink}
                  to={l.to}
                  variant="body1"
                >
                  {l.name}
                </Typography>
              );
            })}
          </Box>
        </Box>
        {/* <Box sx={{ mt: 1 }}>
          <a
            alt="bestchange.com"
            style={{ display: "block" }}
            href="https://www.bestchange.ru/"
            target="_blank"
          >
            <img alt="bestchange.com" src={bestch} width="100px" />
          </a>
        </Box> */}
      </Container>
    </Box>
  );
}
