import React from "react";
import { Box, Typography, Button } from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";

export default function BalanceCard({ title, balance, openHandler }) {
  return (
    <React.Fragment>
      <Typography variant="h6" fontWeight="normal">
        {title}
      </Typography>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" fontWeight={600}>
          {new Intl.NumberFormat("ru-RU").format(balance).trim()} RUB
        </Typography>
        {balance >= 100 && (
          <Button
            onClick={openHandler}
            variant="contained"
            endIcon={<PaidIcon />}
          >
            Заказать выплату
          </Button>
        )}
      </Box>
    </React.Fragment>
  );
}
