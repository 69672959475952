import React, { useState } from "react";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { observer } from "mobx-react";
import ContentStore from "../store/content";
import parse from "html-react-parser";

function HeaderNotify() {
  let content = ContentStore.content;
  let notification = content?.notification?.rus;

  let [show, setShow] = useState(true);

  console.log(notification, 'notification')

  if (!show || !notification) return null;

  return (
    <Box sx={{ py: 1, bgcolor: "#475259b8", color: "white" }}>
      <Grid
        sx={{ mt: "0px" }}
        alignItems="center"
        justifyContent="space-between"
        container
        spacing={2}
      >
        <Grid item xs={2}></Grid>
        <Grid xs={8}>
          <Typography variant="body1" sx={{ width: "100%" }} align="center">
            {parse(notification)}
          </Typography>
        </Grid>
        <Grid xs={2}>
          <IconButton
            sx={{ float: "right", mr: 1, color: 'white' }}
            onClick={() => setShow(false)}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default observer(HeaderNotify);
