import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useCountdown } from "../../common/countDown";
import ClockPng from "../../assets/images/break-back.png";

function TechnicalBreak({ data }) {
  const [hours, minutes, seconds] = useCountdown(data.stopped);

  function formatTime(hour, min, sec) {
    let hours = `${hour}`.length < 2 ? `0${hour}` : hour;
    let minutes = `${min}`.length < 2 ? `0${min}` : min;
    let seconds = `${sec}`.length < 2 ? `0${sec}` : sec;

    return `${hours}:${minutes}:${seconds}`;
  }

  let time =
    minutes + seconds + hours <= 0
      ? "Включаемся"
      : formatTime(hours, minutes, seconds);

  return (
    <Container maxWidth="md">
      <Box sx={{ p: 1, py: 3 }}>
        <Grid alignItems="center" container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" fontWeight="normal">
              Технический перерыв
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }} fontWeight={400}>
              Сервис будет доступен через:
            </Typography>
            <Typography variant="h3" fontWeight="normal">
              {time}
            </Typography>
            <Typography sx={{ mt: 2 }} variant="body1" fontWeight="normal">
              В случае если у вас есть вопросы, напишите нам в чат...
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              alt="Технический перерыв"
              style={{
                width: "100%",
                margin: "20px auto",
                display: "block",
              }}
              src={ClockPng}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default TechnicalBreak;
