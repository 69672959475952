export default function initTakeSelectedCurrency(
  exchanges,
  currencies,
  selected,
  setSelected,
  urlCur
) {
  let urlExc = null;

  if (urlCur) {
    let { from, to } = urlCur;
    urlExc = exchanges.find(
      (e) => e.take.bestchangeCode == from && e.give.bestchangeCode == to
    );
  }

  if (urlExc && !selected) {
    setSelected(urlExc.take);
  } else if (!selected && currencies.length !== 0) {
    setSelected(currencies[0]);
  }
}
