import React from "react";
import moment from "moment";
import "moment/locale/ru";
import { Card, Box, Typography, CardActionArea } from "@mui/material";

moment.locale("ru");

function NewsCard({ data }) {
  const { title, created } = data;
  return (
    <Card sx={{ height: "100%" }}>
      <CardActionArea
        sx={{
          py: 1,
          px: 1.5,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "stretch",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 400 }}>
          {title}
        </Typography>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" sx={{color: '#475259c4'}}>
            {moment(created).format("D MMM YYYY")}
          </Typography>
          <Typography variant="overline">Читать</Typography>
        </Box>
      </CardActionArea>
    </Card>
  );
}

export default NewsCard;
