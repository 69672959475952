import React, { useState } from "react";
import { observer } from "mobx-react";
import NewsCard from "./NewsCard";
import StoreContent from "../../store/content";
import NewsModalContent from "./NewsModalContent";
import { Modal, Grid, Typography, Box, Container } from "@mui/material";

function NewsPage() {
  const [showModal, setShowModal] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  const newsList = StoreContent.content.news;

  function openNews(news) {
    setSelectedNews(news);
    setShowModal(true);
  }

  function closeNews() {
    setShowModal(false);
    setSelectedNews(null);
  }

  return (
    <Box>
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          textAlign="center"
          sx={{ mb: 5, mx: 1, fontWeight: 400,  }}
        >
          Новости
        </Typography>
        <Grid alignItems="stretch" container spacing={2}>
          {newsList.map((n) => {
            return (
              <Grid
                onClick={() => openNews(n)}
                key={n._id}
                item
                xs={12}
                md={6}
                lg={4}
              >
                <NewsCard data={n} />
              </Grid>
            );
          })}
        </Grid>
      </Container>
      <Modal open={showModal} onClose={closeNews}>
        <NewsModalContent closeHandler={closeNews} news={selectedNews} />
      </Modal>
    </Box>
  );
}

export default observer(NewsPage);
