import React, { useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Tooltip,
  TextField,
  styled,
  Avatar,
  Typography,
  CardActionArea,
  Divider,
  Box,
  alpha,
  Button,
} from "@mui/material";

export default function History({ list }) {
  let sectionEmpty = (
    <Typography sx={{ p: 1 }} variant="subtitle1" align="center">
      У вас пока нет реферальных начислений
    </Typography>
  );
  let sectionList = (
    <React.Fragment>
      <Typography sx={{ p: 1 }} variant="subtitle1" align="center">
        История всех начислений
      </Typography>
      <Box sx={{ p: 2, pt: 0 }}>
        <Grid container spacing={2}>
          {list.map((r) => {
            return (
              <Grid xs={12} sm={4} item>
                <Box>
                  <Box
                    sx={{
                      bgcolor: "#f0f8ff",
                      borderRadius: 2,
                      p: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      fontWeight={600}
                      sx={{ color: "#37373c", fontFamily: "Arial" }}
                      variant="h6"
                    >
                      {r.sum} RUB
                    </Typography>
                    <Typography
                      fontWeight={400}
                      sx={{ color: "#8595a6", fontSize: "14px" }}
                    >
                      {r.date}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </React.Fragment>
  );

  let data = list.length === 0 ? sectionEmpty : sectionList;
  return data;
}
