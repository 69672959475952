import axios from "axios";
import accountStore from "../store/profile";

class OrderService {
  //   let data = { hash: this.order.hash, type: "payment" };
  //   let data = { hash: this.order.hash, type: "reject" };

  getAll() {
    return axios.get("profile/orders");
  }
  getOne(hash) {
    return axios.get(`/orders/${hash}`);
  }

  create(data) {
    return axios.post("orders", data);
  }
  change(data) {
    return axios.put("profile/orders", data);
  }
}

const orderService = new OrderService();

export default orderService;
