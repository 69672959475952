import React from "react";
import { Box, CircularProgress } from "@mui/material";

function Loader({ title, style = {}, children }) {
  return (
    <Box
      sx={{
        p: 2,
        bgcolor: "#e3f2fa",
        borderRadius: 1.5,
        ...style,
      }}
    >
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <CircularProgress color="primary" />
        <Box>{children}</Box>
      </Box>
    </Box>
  );
}

export default Loader;
