import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Tooltip,
  TextField,
  styled,
  Avatar,
  CardActionArea,
  Divider,
} from "@mui/material";
import CommonSettings from "./CommonSettings";
import PasswordSettings from "./PasswordSettings";
import LevelInfo from "./LevelInfo";

function Settings() {
  return (
    <Card sx={{ p: 2 }}>
      {/* <LevelInfo /> */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <CommonSettings />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <PasswordSettings />
        </Grid>
      </Grid>
    </Card>
  );
}

export default Settings;
