import React from "react";
import {
  Typography,
  Box,
  Container,
  Card,
  Grid,
  Paper,
  useTheme,
} from "@mui/material";
import parse from "html-react-parser";
import StoreContent from "../../store/content";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ForumIcon from "@mui/icons-material/Forum";
import img1 from "../../assets/images/contact-back-1.png";

export default function ContactsPage() {
  // let theme = useTheme();
  const contacts = StoreContent.content.contacts;
  return (
    <Container maxWidth="md">
      <Typography
        variant="h3"
        textAlign="center"
        sx={{ mb: 5, mx: 1, fontWeight: 400 }}
      >
        Контакты
      </Typography>

      <Card sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Typography variant="h5">Связаться с нами</Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Если у Вас возникли какие либо вопросы технического или
              финансового характера, напишите нам и мы поможем Вам в решении
              вашего вопроса. Мы отвечаем на вопросы в течение 15-30 минут, в
              зависимости от нагрузки сервиса.
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <img src={img1} width="100%" />
          </Grid>
        </Grid>

        <Grid sx={{ mt: 2 }} container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                bgcolor: "#3bd2a257",
                py: 2,
              }}
            >
              <AccessTimeIcon fontSize="large" />
              <Typography variant="h5">Время работы</Typography>
              <Typography variant="body1" sx={{ color: "#475259c4" }}>
                Круглосуточно
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                bgcolor: "#3bd2a257",
                py: 2,
              }}
            >
              <ContactMailIcon fontSize="large" />
              <Typography variant="h5">Почта</Typography>
              <Typography
                component="a"
                href="mailto:abc@example.com"
                variant="body1"
                sx={{ color: "#475259c4" }}
              >
                support@menyatt.com
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                bgcolor: "#3bd2a257",
                py: 2,
                cursor: 'pointer'
              }}
              onClick={()=>{
                window.Chatra("openChat");
              }}
            >
              <ForumIcon fontSize="large" />
              <Typography variant="h5">Чат онлайн</Typography>
              <Typography variant="body1" sx={{ color: "#475259c4" }}>
                Открыть чат
              </Typography>
            </Card>
          </Grid>
        </Grid>

        {/* <Typography variant="h5" sx={{ mt: 3 }}>
          Отзывы о нас
        </Typography>

        <Typography variant="body1" sx={{ mt: 2 }}>
          Если у Вас возникли вопросы технического или финансового плана,
          напишите нам и мы поможем Вам в решении вашего вопроса. Мы отвечаем на
          вопросы в течение 15-60 минут, в зависимости от загрузки сервиса.
        </Typography>
        <Typography variant="body1" component="div">
          {parse(contacts)}
        </Typography> */}
      </Card>
    </Container>
  );
}
