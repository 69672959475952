import React, { useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Tooltip,
  TextField,
  styled,
  Avatar,
  Typography,
  CardActionArea,
  Divider,
  Box,
  alpha,
  Button,
} from "@mui/material";
import CashbackCard from "./CashbackCard";

export default function History({ list }) {
  let sectionEmpty = (
    <Typography sx={{ p: 1 }} variant="subtitle1" align="center">
      У вас пока нет кэшбэк начислений
    </Typography>
  );
  let sectionList = (
    <React.Fragment>
      <Typography sx={{ pt: 1 }} variant="subtitle1" align="center">
        История всех начислений
      </Typography>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          {list.map((r) => {
            return (
              <Grid xs={12} sm={4} item>
                <CashbackCard data={r} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </React.Fragment>
  );

  let data = list.length == 0 ? sectionEmpty : sectionList;

  return data;
}
