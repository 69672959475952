export default function chaneImage(e, setImage, setFile) {
  const files = e.target.files || e.dataTransfer.files;
  if (!files.length) {
    return;
  }
  let size = files[0].size;
  if (size <= 16777216) {
    checkImage(files[0]);
  }
  function checkImage(file) {
    const reader = new FileReader();
    reader.onloadend = (e) => {
      const arr = new Uint8Array(e.target.result).subarray(0, 4);
      let header = "";
      let isImage = false;
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      switch (header) {
        case "89504e47":
          isImage = true;
          break;
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
        case "ffd8ffdb":
        case "ffd8ffed":
          isImage = true;
          break;
        default:
          isImage = false;
          break;
      }
      if (isImage) {
        setFile(file);
        createImage(file);
      } else {
        // this.error = "Изображение должно иметь расширение .jpg, .jpeg, .png";
      }
    };
    reader.readAsArrayBuffer(file);
  }
  function createImage(file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      setImage(e.target.result);
    };
    reader.readAsDataURL(file);
  }
}
