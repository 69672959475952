import React from "react";
import { alpha, Avatar, styled } from "@mui/material";

const Wrap = styled(Avatar)(
  ({ theme }) => `
        // margin: ${theme.spacing(2, 0, 1, -0.5)};
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-right: ${theme.spacing(1)};
        padding: ${theme.spacing(0.5)};
        border-radius: 60px;
        height: ${theme.spacing(5.5)};
        width: ${theme.spacing(5.5)};
        background: ${
          theme.palette.mode === "dark"
            ? theme.colors.alpha.trueWhite[30]
            : alpha(theme.colors.alpha.black[100], 0.07)
        };
      
        img {
          background: ${theme.colors.alpha.trueWhite[100]};
          padding: ${theme.spacing(0.5)};
          display: block;
          border-radius: inherit;
          height: ${theme.spacing(4.5)};
          width: ${theme.spacing(4.5)};
        }
    `
);

function CurrencyImg({ img, style, alt }) {
  return (
    <Wrap sx={{ ...style }}>
      <img alt={alt} src={img} />
    </Wrap>
  );
}

export default CurrencyImg;
