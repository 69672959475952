import React from "react";
import { Typography, Box, Container, Card, Grid } from "@mui/material";
import ContentStore from "../../store/content";
import parse from "html-react-parser";
import { observer } from "mobx-react";

function AgreementPage() {
  let agreement = ContentStore.content.agreement.rus;

  return (
    <Container maxWidth="lg">
      <Typography
        variant="h3"
        textAlign="center"
        sx={{ mb: 5, mx: 1, fontWeight: 400 }}
      >
       Пользовательское соглашение и политика AML/KYC.
      </Typography>

      <Card sx={{ p: 2 }}>
        <Typography variant="subtitle1">{parse(agreement)}</Typography>
      </Card>
    </Container>
  );
}
export default observer(AgreementPage);
