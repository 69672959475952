export default function getCardStatusText(code) {
  switch (code) {
    case 101:
      return "verification";
    case 102:
      return "resolved";
    case 103:
      return "rejected";
    case 104:
      return "banned";
    default:
      return "";
  }
}
