import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#3bd2a2",
    },
    secondary: {
      main: "#7352c7",
    },
    background: {
      default: "#f2f4f8",
    },
    text: {
      primary: "#343d41",
      fontFamily: "NoirPro",
    },
  },
  typography: {
    fontFamily: "NoirPro",
  },

  components: {
    MuiPaper: {
      root: {
        padding: "10px",
        marginBottom: "10px",
        backgroundColor: "red",
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#1e2228",
          "letter-spacing": "2px",
          fontWeight: 500,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          "border-bottom": "1px solid #e1e6ef",
          "border-top": "1px solid #e1e6ef",
          "& th": {
            "font-size": "14px",
            color: "#151b1e",
            "font-weight": "bold",
            "text-align": "center",
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "border-bottom": "1px solid #e1e6ef",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          boxShadow: "rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem",
          borderRadius: "12px",
          border: "none",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          // border: "1px solid gray",
          padding: "10px",
          textAlign: "center",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#6b6b6b #ACACAC",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#ACACAC",
            borderRadius: 8,
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#6b6b6b",
            minHeight: 24,
            border: "3px solid #ACACAC",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#959595",
            },
        },
      },
    },
  },
});

export default theme;
