import {
  makeObservable,
  observable,
  action,
  autorun,
  flow,
  computed,
} from "mobx";
import ProfileService from "../services/Profile";
import config from "../config";

class Profile {
  profile = null;
  ref = null;
  constructor() {
    makeObservable(this, {
      profile: observable,
      setProfile: action,
      logout: action,
      setRef: action,
      isAuth: computed,
      isVerified: computed,
      referralLink: computed,
      getRef: computed,
      isPermanent: computed,
      fetchSignUp: flow,
      fetchSignIn: flow,
      fetchRecoverPassword: flow,
      fetchProfile: flow,
      changePassword: flow,
      deleteProfile: flow,
    });
  }

  get isAuth() {
    return !!this.profile;
  }

  get isPermanent() {
    return this.profile.serviceStatus == "permanent";
  }

  get isVerified() {
    return this.profile.verified;
  }

  get referralLink() {
    if (!this.profile.referral) return "";
    return `${config.DOMAIN}?ref=` + this.profile.referral.link;
  }

  setRef(ref) {
    this.ref = ref;
  }
  get getRef() {
    return this.ref;
  }

  setProfile(data) {
    let oldUser = this.profile;
    let newUser = data;

    if (oldUser) {
      newUser.accrual =
        data.accrual && typeof data.accrual === "object"
          ? data.accrual
          : oldUser.accrual;
      newUser.referral =
        data.referral && typeof data.referral === "object"
          ? data.referral
          : oldUser.referral;
    }

    this.profile = newUser;
  }

  logout() {
    localStorage.removeItem("token");
    this.profile = null;
  }
  *fetchSignUp(data, setError, successHandler, finallyHandler) {
    ProfileService.signUp(data)
      .then((res) => {
        let data = res.data;
        let token = data.token;
        let user = data.user;
        localStorage.setItem("token", token);
        this.setProfile(user);
        successHandler();
      })
      .catch((e) => {
        let error = e.response.data.error;
        setError(error);
      })
      .finally(finallyHandler);
  }
  *fetchSignIn(data, setError, successHandler, finallyHandler) {
    ProfileService.signIn(data)
      .then((res) => {
        let data = res.data;
        let token = data.token;
        let user = data.user;
        localStorage.setItem("token", token);
        this.setProfile(user);
        successHandler();
      })
      .catch((e) => {
        let error = e.response.data.error;
        setError(error);
      })
      .finally(finallyHandler);
  }
  *fetchRecoverPassword(data, setSucces, setError, finallyHandler) {
    ProfileService.resetPassword(data)
      .then((res) => {
        let data = res.data;
        setSucces();
      })
      .catch((e) => {
        let error = e.response.data.error;
        setError(error);
      })
      .finally(finallyHandler);
  }
  *fetchProfile(finishHandler, errorHandler) {
    ProfileService.getProfile()
      .then((res) => {
        let data = res.data;
        this.setProfile(data);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(finishHandler);
  }

  *changePassword(data, successHandler, errorHandler, finallyHandler) {
    ProfileService.changePassword(data)
      .then((res) => {
        successHandler();
      })
      .catch((e) => {
        let error = e.response.data;
        errorHandler(error);
      })
      .finally(finallyHandler);
  }
  *deleteProfile(successHandler, finallyHandler) {
    ProfileService.deleteAccount()
      .then((res) => {
        successHandler();
        this.logout();
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(finallyHandler);
  }
}

const profileStore = new Profile();

export default profileStore;
