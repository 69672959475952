import React from "react";
import {
  Container,
  Card,
  Typography,
  Divider,
  Box,
  Paper,
  alpha,
  Grid,
  styled,
  Link,
  useTheme,
  Button,
} from "@mui/material";
import Transaction from "./Transaction";
import RateReviewIcon from "@mui/icons-material/RateReview";
import ExchangePair from "./ExchangePair";
import getTransactionLink from "./methods/getTransactionLink";

function ReviewsLink() {
  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        // bgcolor: "#f0f8ff",
        borderRadius: 2,
        height: "100%",
      }}
    >
      <Typography fontWeight={400} variant="body1">
        Оставить отзыв на:
        <Button
          variant="outlined"
          component={Link}
          sx={{ mt: 1 }}
          href="https://www.bestchange.ru/menyatt-exchanger.html"
          target="_blank"
        >
          Bestchange.com
        </Button>
      </Typography>
    </Box>
  );
}

function Resolved({ order }) {
  let trans = getTransactionLink(order, "give");

  return (
    <Card>
      <Box sx={{ pt: 2, px: 2 }}>
        <Grid container>
          <Grid item xs={12} md={7}>
            <Typography sx={{ mb: 1.5 }} variant="h5" fontWeight="normal">
              Заявка выполнена
            </Typography>
            <Typography sx={{ mb: 0.5, color: "#475259" }} variant="body1">
              Средства успешно переведены на ваш счёт.
            </Typography>
            <Typography sx={{ mb: 0.5, color: "#475259" }} variant="body1">
              Если вы хотите способствовать улучшению обслуживания нашего
              сервиса и дальнейшему его развитию, то оставьте о нас отзыв.
            </Typography>

            {trans && (
              <Transaction
                style={{ mt: 3 }}
                address={trans.address}
                img={order.currency.give.image}
                link={trans.link}
              />
            )}
          </Grid>
          <Grid xs={12} md={5}>
            {/* <ReviewsLink /> */}
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ mt: 1 }} />
      <Box sx={{ px: 2, py: 1 }}>
        <Grid alignItems="center" container spacing={2}>
          <Grid item xs={12} md={6}>
            <ExchangePair
              take={{
                value: order.fields.take.find((f) => f.isAddress || f.isCard)
                  ?.value,
                name: order.currency.take.name,
                code: order.currency.take.code,
                img: order.currency.take.image,
                amount: order.amount.take,
              }}
              give={{
                value: order.fields.give.find((f) => f.isAddress || f.isCard)
                  ?.value,
                name: order.currency.give.name,
                code: order.currency.give.code,
                img: order.currency.give.image,
                amount: order.amount.give,
              }}
            />
          </Grid>
        </Grid>
      </Box>
      {/*

      <Box sx={{ mt: 3 }}>
        <Grid alignItems="strecth" container spacing={2}>
          <Grid item xs={12} md={6}>
            <ExchangePair
              // style={{ mt: 2 }}
              // horizontal
              take={{
                name: order.currency.take.name,
                code: order.currency.take.code,
                img: order.currency.take.image,
                amount: order.amount.take,
              }}
              give={{
                name: order.currency.give.name,
                code: order.currency.give.code,
                img: order.currency.give.image,
                amount: order.amount.give,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            
          </Grid>
        </Grid>
      </Box> */}
    </Card>
  );
}

export default Resolved;
