import React from "react";
import {
  Typography,
  Box,
  Container,
  Card,
  alpha,
  Avatar,
  styled,
} from "@mui/material";
import getImgUrl from "../../common/getImgUrl";

function CurrencyCard({ name, amount = "", code = "", img, style = {} }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", ...style }}>
      <Avatar src={getImgUrl(img)} sx={{ width: 45, height: 45 }} />
      <Box sx={{ ml: 1.5 }}>
        <Typography sx={{ color: "#8595a6", fontSize: "16px" }}>
          {name}
        </Typography>
        <Typography sx={{ color: "#37373c", fontSize: "18px" }}>
          {amount} {code}
        </Typography>
      </Box>
    </Box>
  );
}

export default CurrencyCard;
