import React from "react";
import {
  Container,
  Card,
  Typography,
  Divider,
  Box,
  Paper,
  alpha,
  styled,
  Link,
  Grid,
  Button,
} from "@mui/material";
import CopyToClipboard from "../CopyToClipboard/CopyToClipboard";

function Field({ name, value }) {
  return (
    <Box>
      <Typography sx={{ color: "#475259" }} variant="subtitle1">
        {name}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="body1" fontWeight="medium" sx={{ mr: 1 }} noWrap>
          {value}
        </Typography>
        <CopyToClipboard value={value} />
      </Box>
    </Box>
  );
}

export default Field;
