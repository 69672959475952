const SERVER =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://api.menyatt.com";

const config = {
  API: `${SERVER}/api`,
  SERVER,
  API_IMAGES: `${SERVER}/images`,
  DOMAIN: "https://menyatt.com/",
};

// /images/${take.image}.png`,

export default config;
