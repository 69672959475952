import React, { useState } from "react";
import {
  CircularProgress,
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Paper,
  Container,
  Modal,
  FormControlLabel,
  Checkbox,
  Grid,
  Alert,
  InputAdornment,
  TextField,
} from "@mui/material";
import Wrap from "./Wrap";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import ProfileStore from "../../store/profile";
import { EventBus } from "../../event";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import EmailIcon from "@mui/icons-material/Email";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function Login({ handleClose }) {
  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: { email: "", password: "", confirmPassword: "" },
  });
  let [showPassword, setShowPassword] = useState(false);
  let [alert, setAlert] = useState("");
  let [isAgree, setAgree] = useState(true);
  let [isSubmitting, setSubmiting] = useState(false);
  const onSubmit = handleSubmit((data) => {
    let { password, confirmPassword, email, name } = data;

    if (password !== confirmPassword) {
      setError("password", { type: "custom", message: "Пароли не совпадают!" });
      return;
    }

    setSubmiting(true);
    ProfileStore.fetchSignUp(
      { name, email, password, referralLink: ProfileStore.getRef },
      setAlert,
      () => handleClose(),
      () => {
        setSubmiting(false);
      }
    );
  });
  return (
    <Wrap
      title="Уже есть аккаунт?"
      handleClose={handleClose}
      desc="Войдите в свой аккаунт, чтобы следить за статусами заявок, получать прибыль с рефералов и использовать другие преимущества сервиса"
      slot={
        <Button
          sx={{
            display: "block",
            margin: "20px auto 0px",
          }}
          color="primary"
          type="submit"
          onClick={() => EventBus.$emit("show_dialog", "login")}
          size="large"
          variant="contained"
        >
          Войти в аккаунт
        </Button>
      }
    >
      <Box sx={{ py: 5, color: "#1e2228" }}>
        {alert && (
          <Alert variant="filled" severity="error">
            {alert}
          </Alert>
        )}

        <form noValidate onSubmit={onSubmit}>
          <Controller
            name={"name"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                error={Boolean(errors.name?.message)}
                {...register("name", {
                  required: "Обязательное поле!",
                  minLength: {
                    value: 6,
                    message: "Мин. длина имени 6 символов!",
                  },
                })}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon />
                    </InputAdornment>
                  ),
                }}
                margin="normal"
                autoFocus
                helperText={errors.name?.message}
                label="Имя"
                name="name"
                type="text"
                variant="outlined"
                onChange={(p) => {
                  setAlert("");
                  onChange(p);
                }}
                value={value}
              />
            )}
          />
          <Controller
            name={"email"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                error={Boolean(errors.email?.message)}
                {...register("email", {
                  required: "Обязательное поле!",
                  maxLength: 100,
                  pattern: {
                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: "Некорректный Email!",
                  },
                })}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                margin="normal"
                autoFocus
                helperText={errors.email?.message}
                label="Email"
                name="email"
                type="email"
                variant="outlined"
                onChange={(p) => {
                  setAlert("");
                  onChange(p);
                }}
                value={value}
              />
            )}
          />
          <Controller
            name={"password"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                {...register("password", {
                  required: "Обязательное поле!",
                  minLength: {
                    value: 6,
                    message: "Мин. длина пароля 6 символов!",
                  },
                  maxLength: 100,
                })}
                error={Boolean(errors.password?.message)}
                fullWidth
                margin="normal"
                helperText={errors.password?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}
                        edge="start"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label="Пароль"
                name="password"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                onChange={(p) => {
                  setAlert("");
                  onChange(p);
                }}
                value={value}
              />
            )}
          />
          <Controller
            name={"confirmPassword"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                {...register("confirmPassword", {
                  required: "Обязательное поле!",
                  minLength: {
                    value: 6,
                    message: "Мин. длина пароля 6 символов!",
                  },
                  maxLength: 100,
                })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Visibility />
                    </InputAdornment>
                  ),
                }}
                error={Boolean(errors.confirmPassword?.message)}
                fullWidth
                margin="normal"
                helperText={errors.confirmPassword?.message}
                label="Пароль"
                name="confirmPassword"
                type="password"
                variant="outlined"
                onChange={(p) => {
                  setAlert("");
                  onChange(p);
                }}
                value={value}
              />
            )}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={isAgree}
                onChange={(e) => setAgree(e.target.checked)}
              />
            }
            label={
              <Box>
                Я согласен с{" "}
                <Link
                  target="_blank"
                  style={{ color: "#3bd2a2" }}
                  to="/agreement"
                >
                  правилами работы сервиса
                </Link>
              </Box>
            }
          />
          <Button
            sx={{
              mt: 3,
            }}
            color="primary"
            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmitting || !isAgree}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
          >
            Создать аккаунт
          </Button>
        </form>
      </Box>
    </Wrap>
  );
}
