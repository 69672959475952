export default function getOrderStatusText(code) {
  switch (code) {
    case 101:
      return "verification";
    case 102:
      return "payment-user";
    case 103:
      return "check-payment";
    case 104:
      return "payment-service";
    case 105:
      return "resolved";
    case 106:
      return "rejected";
    case 107:
      return "checked";
    case 108:
      return "error";
    default:
      return null;
  }
}
