import { Typography, Box } from "@mui/material";

let colors = {
  yellow: "#ffff00",
  green: "#7cfc00",
  red: "#ff0000",
};

export default function Badge({ name, color }) {
  let bgcolor = colors[color];
  return (
    <Box sx={{ bgcolor, borderRadius: "10px", px: 1, py: 0.2 }}>
      <Typography
        variant="body1"
        sx={{ color: "white", textShadow: "1px 1px 1px #475259c4" }}
      >
        {name}
      </Typography>
    </Box>
  );
}
