import React from "react";
import {
  Card,
  Box,
  Typography,
  Avatar,
  TextField,
  IconButton,
  styled,
} from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import getImgLink from "../../common/getImgLink";

const StyledTextField = styled(TextField)({
  "&:hover  .MuiInput-underline:before": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },
  "&  .MuiInput-input:hover.MuiInput-underline:before, & .MuiInput-input:hover.MuiInput-underline:after,":
    {
      borderBottom: "none",
    },
  "& .MuiInput-input": {
    fontSize: "25px",
    "-webkit-user-select": "number !important" /* Chrome, Opera, Safari */,
    "-moz-user-select": "number !important" /* Firefox 2+ */,
    "-ms-user-select": "number !important" /* IE 10+ */,
    "user-select": "number !important" /* Standard syntax */,
  },

  "& .MuiInput-input::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& .MuiInput-input::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& .MuiFormHelperText-root.Mui-error ": {
    position: "absolute",
    top: "45px",
    marginLeft: "0px",
    fontSize: "14px",
  },
});

function SelectedCurrency({
  side,
  img,
  code,
  amount,
  changeAmount,
  onBlurAmount,
  handleOpen,
  error,
}) {
  function changeValueHandler(e) {
   
    let value = e.target.value;

    changeAmount(value);
    // if (value == '') {
    //   changeAmount('');
    // } else {
    //   changeAmount( Number(value.replace(/[^0-9.-]+/g,""))+'');
    // }
  }

  let amountError = error && error[side];
  return (
    <Card
      sx={{
        py: 2,
        px: 2,
        bgcolor: amountError ? "#fff0f0" : "",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Avatar
          src={getImgLink(img)}
          sx={{
            width: 65,
            height: 65,
            mr: 1,
          }}
        />

        <StyledTextField
          type="number"
          pattern="\d*"
          //   inputProps={{
          //     inputMode: 'decimal',
          // }}
          value={amount}
          onBlur={() => onBlurAmount(side)}
          onChange={changeValueHandler}
          sx={{ flex: 1, mx: 1 }}
          error={!!amountError}
          helperText={amountError}
          // inputProps={{ inputMode: 'numeric' }}
          variant="standard"
          InputProps={{ disableUnderline: true, inputMode: "decimal" }}
        />

        <Typography variant="h5" fontWeight={300}>
          {code}
        </Typography>
        <IconButton onClick={handleOpen} size="large" sx={{ ml: 1 }}>
          <UnfoldMoreIcon size="large" />
        </IconButton>
      </Box>
    </Card>
  );
}

export default SelectedCurrency;
