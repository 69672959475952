import React from "react";
import { Button, ButtonGroup, Tooltip } from "@mui/material";

function CurrenciesFilter({
  filterList,
  activeFilter,
  setActiveFilter,
  styles = {},
}) {
  return (
    <ButtonGroup sx={{ bgcolor: "white", ...styles }}>
      {filterList.map((f) => (
        <Tooltip key={f.id} title={f.title}>
          <Button
            onClick={() => setActiveFilter(f.id)}
            sx={{
              color: "inherit",
              fontWeight: 500,
            }}
            variant={activeFilter == f.id ? "contained" : "outlined"}
          >
            {f.name}
          </Button>
        </Tooltip>
      ))}
    </ButtonGroup>
  );
}

export default CurrenciesFilter;
