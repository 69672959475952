import React, { useState, useEffect, useMemo, useRef } from "react";
import { Box, Container, Grid, Typography, Popover } from "@mui/material";
import SelectedCurrency from "./SelectedCurrency";
import fetchExchanges from "./methods/fetchExchanges";

// import changeExchangeId from "./methods/changeExchangeId";
// import fetchExchange from "./methods/fetchExchange";
import CurrenciesList from "./CurrenciesList/CurrenciesList";
import Fields from "./Fields/Fields";
import CurrencyInfo from "./CurrencyInfo/CurrencyInfo";

// import fetchCityCodes from "./methods/fetchCityCodes";
import getCity from "./methods/getCity";
// import fetchBreak from "./methods/fetchBreak";
import TechnicalBreak from "./TechnicalBreak";
// import { socket } from "../../socket";
import validateAmount from "./methods/validateAmount";

// Logic
import GetExchangesTechBreakCityCodes from "./GetExchangesTechBreakCityCodes";
import ListState from "./ListState";
import SelectedCurrencies from "./SelectedCurrencies";
import ExchangeState from "./ExchangeState";
import CalculateAmount from "./CalculateAmount";

//

function Exchanger() {
  let { techBreak, urlCurr, exchanges, cities, setUrlCurr } =
    GetExchangesTechBreakCityCodes();
  let {
    takelistRef,
    givelistRef,
    isOpenTakeList,
    isOpenGiveList,
    handleOpenList,
    handleCloseList,
  } = ListState();

  let {
    takeCurrencies,
    giveCurrencies,
    takeSelectedCurrency,
    setTakeSelectedCurrency,
    giveSelectedCurrency,
    setGiveSelectedCurrency,
  } = SelectedCurrencies({
    exchanges,
    urlCurr,
    setUrlCurr,
  });

  let { exchange, isFetchExchange } = ExchangeState({
    takeSelectedCurrency,
    giveSelectedCurrency,
    exchanges,
  });

  let {
    takeAmount,
    giveAmount,
    lastInput,
    changeAmount,
    changeTakeAmount,
    changeGiveAmount,
    validateAmountHandler,
    newMin,
    amountError,
    commissionInfo,
  } = CalculateAmount({
    exchange,
  });

  let cityData = getCity(exchange, cities);

  if (techBreak?.enabled) {
    return <TechnicalBreak data={techBreak} />;
  }

  return (
    <Box>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {takeSelectedCurrency && (
              <Typography variant="h4" fontWeight="normal" sx={{ mb: 2 }}>
                Отдаёте
              </Typography>
            )}

            {takeSelectedCurrency && (
              <SelectedCurrency
                handleOpen={() => handleOpenList("take")}
                side="take"
                onBlurAmount={validateAmountHandler}
                code={takeSelectedCurrency.code}
                img={takeSelectedCurrency.image}
                amount={takeAmount}
                changeAmount={changeTakeAmount}
                error={amountError}
              />
            )}
            <Box
              ref={takelistRef}
              sx={{
                width: "100%",
                height: "1px",
                backgroundColor: "transparent",
              }}
            />

            {exchange && (
              <CurrencyInfo
                city={cityData && cityData.side == "take" ? cityData.value : ""}
                newMin={newMin}
                exchange={exchange}
                side="take"
              />
            )}

            <Popover
              disableScrollLock
              anchorEl={takelistRef.current}
              onClose={handleCloseList}
              open={isOpenTakeList}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                style: { width: "906px" },
              }}
            >
              <CurrenciesList
                setCurrency={setTakeSelectedCurrency}
                closeHandler={handleCloseList}
                side="take"
                selected={takeSelectedCurrency}
                currencies={takeCurrencies}
              />
            </Popover>
          </Grid>
          <Grid item xs={12} sm={6}>
            {giveSelectedCurrency && (
              <Typography variant="h4" fontWeight="normal" sx={{ mb: 2 }}>
                Получаете
              </Typography>
            )}

            {giveSelectedCurrency && (
              <SelectedCurrency
                side="give"
                handleOpen={() => handleOpenList("give")}
                onBlurAmount={validateAmountHandler}
                code={giveSelectedCurrency.code}
                img={giveSelectedCurrency.image}
                amount={giveAmount}
                changeAmount={changeGiveAmount}
                error={amountError}
              />
            )}
            <Box
              ref={givelistRef}
              sx={{
                width: "100%",
                height: "1px",
                backgroundColor: "transparent",
              }}
            />
            {exchange && (
              <CurrencyInfo
                city={cityData && cityData.side == "give" ? cityData.value : ""}
                commissionInfo={commissionInfo}
                exchange={exchange}
                side="give"
              />
            )}

            <Popover
              disableScrollLock
              anchorEl={givelistRef.current}
              onClose={handleCloseList}
              open={isOpenGiveList}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                style: { width: "906px" },
              }}
            >
              <CurrenciesList
                setCurrency={setGiveSelectedCurrency}
                closeHandler={handleCloseList}
                side="give"
                selected={giveSelectedCurrency}
                currencies={giveCurrencies}
              />
            </Popover>
          </Grid>
        </Grid>
        <Fields
          isFetchExchange={isFetchExchange}
          exchange={exchange}
          takeAmount={takeAmount}
          giveAmount={giveAmount}
        />
      </Container>
    </Box>
  );
}

export default Exchanger;
