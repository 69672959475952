export default function getOrderStatusName(status) {
  switch (status) {
    case "resolved":
      return "Одобрена";
    case "rejected":
      return "Отклонена";
    case "checked":
      return "Выполнено";
    default:
      return "Новая";
  }
}
