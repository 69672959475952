import React, { useState } from "react";
import {
  CircularProgress,
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Paper,
  Container,
  Modal,
  Grid,
  Alert,
  InputAdornment,
  TextField,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import Wrap from "./Wrap";
import { useForm, Controller } from "react-hook-form";
import ProfileStore from "../../store/profile";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { EventBus } from "../../event";

export default function Login({ handleClose }) {
  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: { email: "", password: "" },
  });
  let [alert, setAlert] = useState("");
  let [isSubmitting, setSubmiting] = useState(false);
  let [showPassword, setShowPassword] = useState(false);
  const onSubmit = handleSubmit((data) => {
    setSubmiting(true);
    ProfileStore.fetchSignIn(
      { email: data.email, password: data.password },
      (e) => {
        setAlert(e);
      },
      () => handleClose(),
      () => {
        setSubmiting(false);
      }
    );
  });
  return (
    <Wrap
      title="Ещё нет аккаунта?"
      desc="Пройдите регистрацию и получите доступ к лк"
      handleClose={handleClose}
      slot={
        <Button
          sx={{
            display: "block",
            margin: "20px auto 0px",
          }}
          color="primary"
          type="submit"
          onClick={() => EventBus.$emit("show_dialog", "reg")}
          size="large"
          variant="contained"
        >
          Создать аккаунт
        </Button>
      }
    >
      <Box sx={{ py: 5, color: "#1e2228" }}>
        {alert && (
          <Alert variant="filled" severity="error">
            {alert}
          </Alert>
        )}

        <form noValidate onSubmit={onSubmit}>
          <Controller
            name={"email"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                error={Boolean(errors.email?.message)}
                {...register("email", {
                  required: "Обязательное поле!",
                  maxLength: 100,
                  pattern: {
                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: "Некорректный Email!",
                  },
                })}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                margin="normal"
                autoFocus
                helperText={errors.email?.message}
                label="Email"
                name="email"
                type="email"
                variant="outlined"
                onChange={(p) => {
                  setAlert("");
                  onChange(p);
                }}
                value={value}
              />
            )}
          />
          <Controller
            name={"password"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                {...register("password", {
                  required: "Обязательное поле!",
                  minLength: {
                    value: 6,
                    message: "Мин. длина пароля 6 символов!",
                  },
                  maxLength: 100,
                })}
                error={Boolean(errors.password?.message)}
                fullWidth
                margin="normal"
                helperText={errors.password?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}
                        edge="start"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label="Пароль"
                name="password"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                onChange={(p) => {
                  setAlert("");
                  onChange(p);
                }}
                value={value}
              />
            )}
          />
          <Button
            sx={{
              mt: 3,
            }}
            color="primary"
            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmitting}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
          >
            Войти
          </Button>

          <Button
            onClick={() => EventBus.$emit("show_dialog", "recovery_password")}
            variant="text"
            sx={{ mt: 2 }}
          >
            Забыли пароль?
          </Button>
        </form>
      </Box>
    </Wrap>
  );
}
