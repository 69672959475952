import React, { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, Tooltip, IconButton } from "@mui/material";
import copy from "clipboard-copy";

function CopyToClipboard({ value }) {
  let [showTooltip, setShowTooltip] = useState(false);

  function onCopy() {
    copy(value);
    setShowTooltip(true);
  }

  return (
    <Tooltip
      open={showTooltip}
      title={"Скопировано!"}
      leaveDelay={500}
      onClose={() => setShowTooltip(false)}
    >
      <IconButton sx={{ p: 0 }} onClick={onCopy}>
        <ContentCopyIcon sx={{ color: "#288e6e" }} />
      </IconButton>
    </Tooltip>
  );
}

export default CopyToClipboard;
