import * as React from "react";
import {
  Popover,
  Avatar,
  Box,
  Typography,
  Grid,
  IconButton,
  Container,
} from "@mui/material";
import { Link, NavLink } from "react-router-dom";

import PersonIcon from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";
import { EventBus } from "../../event";
import { observer } from "mobx-react";
import ProfileStore from "../../store/profile";
import UserBox from "./UserBox";

function AppHeader() {
  const [anchorUserMenu, setAnchorUserMenu] = React.useState(null);
  let isAuth = ProfileStore.isAuth;
  let profile = ProfileStore.profile;

  const handleClick = (event) => {
    setAnchorUserMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorUserMenu(null);
  };

  let avatarName = "";

  if (isAuth) {
    let { name, email } = profile;
    let n = name || email;
    avatarName = `${n.split(" ")[0][0]}`;
    avatarName = avatarName.toUpperCase();
  }

  return (
    <Box
      sx={{
        bgcolor: "#3bd2a287",
        minHeight: "80px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container
        sx={{
          height: "100%",
          my: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        maxWidth="lg"
      >
        <Grid container>
          <Grid item xs={3}>
            <IconButton
              size="large"
              onClick={() => EventBus.$emit("open_menu")}
              sx={{
                width: 48,
                height: 48,
                mr: 2,
                color: "inherit",
              }}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{ width: "100%", display: "block", color: "inherit" }}
              component={NavLink}
              to="/"
              variant="h4"
              align="center"
            >
              Menyatt
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {!isAuth ? (
              <IconButton
                onClick={() => EventBus.$emit("show_dialog", "login")}
                size="large"
                sx={{
                  float: "right",
                  bgcolor: "white",
                  color: "#37373c",
                  boxShadow: "rgb(0 0 0 / 9%) 0px 5px 10px",
                  transition: "all 0.5s",
                  "&:hover": {
                    bgcolor: "white",
                    color: "rgb(133 149 166)",
                  },
                }}
              >
                <PersonIcon />
              </IconButton>
            ) : (
              <IconButton
                onClick={handleClick}
                size="large"
                sx={{
                  float: "right",
                  p: 0,
                  bgcolor: "white",
                  color: "#37373c",
                  boxShadow: "rgb(0 0 0 / 9%) 0px 5px 10px",
                  transition: "all 0.5s",
                  "&:hover": {
                    bgcolor: "white",
                    color: "rgb(133 149 166)",
                  },
                }}
              >
                <Avatar sx={{ width: 48, height: 48, color: 'inherit' }}>{avatarName}</Avatar>
              </IconButton>
            )}
            <Popover
              id={anchorUserMenu}
              open={!!anchorUserMenu}
              anchorEl={anchorUserMenu}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <UserBox handleClose={handleClose} />
            </Popover>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default observer(AppHeader);
