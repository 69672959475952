import React, { useEffect, useState } from "react";
import GlobalModal from "./components/GlobalModal";
import "./assets/fonts/stylesheet.css";
import "./App.sass";
import getQuery from "./common/getQuery";
import { Box, LinearProgress } from "@mui/material";
import StoreContent from "./store/content";
import StoreProfile from "./store/profile";
import { observer } from "mobx-react";
import DrawerMenu from "./components/DrawerMenu";
import { useNavigate } from "react-router-dom";

import { SnackbarProvider } from "notistack";
import { CssBaseline } from "@mui/material";
import Router from "./Router";
import { connect, authenticate } from "./socket";
import MainSnackbar from "./components/MainSnackbar";
import { EventBus } from "./event";
import AppHeader from "./components/Header/AppHeader";
import AppFooter from "./components/AppFooter";
import HeaderNotify from "./components/HeaderNotify";
import LogoPng from "./assets/logo/logo.png";
import fetchUnauthOrder from "./initServices/fetchUnauthOrder";

const allCookies = document.cookie;

if (!allCookies) {
  setTimeout(() => {
    EventBus.$emit("show_snackbar", {
      mode: "notify",
      text: "Хотим предупредить что продолжая находиться на нашем сайте , Вы соглашаетесь на использование нами ваших cookies. Мы собираем эти данные для улучшения сервиса",
    });
  }, 2000);
}

function App() {
  let navigate = useNavigate();
  let isAuth = StoreProfile.isAuth;
  let [showProgress, setShowProgress] = useState(false);
  let [isSubmit, setSubmit] = useState(true);
  let [isSubmitProfile, setSubmitProfile] = useState(true);

  let query = getQuery();

  useEffect(() => {
    connect();
    let refCode = query.get("ref");
    if (refCode) {
      StoreProfile.setRef(refCode);
    }
    setSubmit(true);
    StoreContent.fetchContent(() => {
      setSubmit(false);
    });

    let token = localStorage.getItem("token");

    if (token) {
      StoreProfile.fetchProfile(
        () => {
          setSubmitProfile(false);
        },
        () => {
          fetchUnauthOrder(navigate);
        }
      );
    } else {
      fetchUnauthOrder(navigate);
      setSubmitProfile(false);
    }

    EventBus.$on("show_progress_bar", (bool) => {
      setShowProgress(bool);
    });

    return () => {
      EventBus.$off("show_progress_bar");
    };
  }, []);

  useEffect(() => {
    if (isAuth) {
      authenticate();
    }
  }, [isAuth]);

  if (isSubmit || isSubmitProfile)
    return (
      <Box
        sx={{
          minHeight: "100vh",
          minWidth: "100vw",
          bgcolor: "#e3f2fa",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <img src={LogoPng} alt="Menyatt.com" width="80" />
        </Box>
      </Box>
    );

  return (
    <SnackbarProvider>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          color: "#343d41",
          minHeight: "100vh",
          fontFamily: "NoirPro",
          position: "relative",
        }}
      >
        {showProgress && (
          <LinearProgress
            sx={{ position: "absolute", top: "0px", left: "0px", right: "0px" }}
            color="inherit"
          />
        )}

        <HeaderNotify />
        <AppHeader />
        <Box sx={{ flexGrow: 1, bgcolor: "#e3f2fa", pt: 3, pb: 3 }}>
          <CssBaseline />
          <GlobalModal />
          <DrawerMenu />
          <MainSnackbar />
          <Router />
        </Box>
        <AppFooter />
      </Box>
    </SnackbarProvider>
  );
}

export default observer(App);
