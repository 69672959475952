import {useState, useEffect, useRef} from 'react';

const ListState = () => {
  const takelistRef = useRef(null);
  const givelistRef = useRef(null);
  const [isOpenTakeList, setOpenTakeList] = useState(false);
  const [isOpenGiveList, setOpenGiveList] = useState(false);

  const handleOpenList = (side) => {
    if (side == "take") {
      setOpenTakeList(true);
    } else {
      setOpenGiveList(true);
    }
  };

  const handleCloseList = () => {
    setOpenTakeList(false);
    setOpenGiveList(false);
  };

  return {
    takelistRef,
    givelistRef,
    isOpenTakeList,
    isOpenGiveList,
    handleOpenList,
    handleCloseList
  }
}

export default ListState;