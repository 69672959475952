import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Card,
  Typography,
  Divider,
  TextField,
  Box,
  Grid,
} from "@mui/material";
import StatusCardIndicator from "./StatusCardIndicator";
import Info from "./Info";
import ccFormat from "../../common/ccFormat.js";

function Resolved({ data }) {
  let { number } = data;
  number = ccFormat(number.replace(/\s/g, ""));

  return (
    <Card>
      <Grid container columns={14}>
        <Grid item xs={14} md={8}>
          <Box sx={{ p: 2 }}>
            <Info title={`Карта ${number} одобрена`} />
          </Box>
        </Grid>
        <Grid sx={{ bgcolor: "#d3d3d36e" }} item xs={14} md={6}>
          <Box
            sx={{
              p: 2,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <StatusCardIndicator status="resolved" />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}

export default Resolved;
