import React, { useState, useEffect } from "react";
import { Snackbar, Box, Alert, SnackbarContent } from "@mui/material";
import { EventBus } from "../event";

let timeoutId;

export default function MainSnackbar() {
  let [showSnackbar, setShowSnackbar] = useState(false);
  let [data, setData] = useState({ mode: "", text: "" });
  useEffect(() => {
    EventBus.$on("show_snackbar", (t) => {
      console.log(t, "t");
      setData(t);
      setShowSnackbar(true);

      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleClose, 3000);
    });
    return () => {
      EventBus.$off("show_snackbar");
    };
  }, []);

  function handleClose() {
    setShowSnackbar(false);
  }

  let isNotify = data.mode == "notify";

  return (
    <Box onClick={() => setShowSnackbar(false)}>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: isNotify ? "center" : "left",
        }}
        open={showSnackbar}
        onClose={handleClose}
      >
        {isNotify ? (
          <SnackbarContent
            message={data.text}
            sx={{ color: "#475259", bgcolor: "white" }}
          />
        ) : (
          <Alert variant="filled" severity={data.mode}>
            {data.text}
          </Alert>
        )}
      </Snackbar>
    </Box>
  );
}
