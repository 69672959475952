import React, { useState } from "react";
import {
  CircularProgress,
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Paper,
  Container,
  Modal,
  Grid,
  Alert,
  InputAdornment,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import { useForm, Controller } from "react-hook-form";
import ProfileStore from "../../store/profile";
import { EventBus } from "../../event";

export default function RecoveryPassword({ handleClose }) {
  let [status, setStatus] = useState("email");
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "onSubmit", defaultValues: { email: "", code: "" } });
  let [isSubmitting, setSubmiting] = useState(false);
  let [alert, setAlert] = useState({ text: "", severity: "" });

  const onSubmit = handleSubmit((data) => {
    let requestData = {};
    requestData.email = data.email.trim();
    if (status === "code") {
      requestData.code = data.code.trim();
    }
    console.log(requestData, "requestData");
    setSubmiting(true);
    ProfileStore.fetchRecoverPassword(
      requestData,
      () => {
        if (status == "email") {
          //   setAlert({
          //     text: "Код успешно отправлен!",
          //     severity: "success",
          //   });
          setStatus("code");
        } else if (status == "code") {
          setStatus("finish");
        }
      },
      (e) => {
        setAlert({ text: e, severity: "error" });
      },
      () => {
        setSubmiting(false);
      }
    );
    // console.log(data, "data send");
    // if (inputMode === "email") {
    //
    // }
    // if (inputMode === "code") {
    //   setInputMode("finish");
    // }
    //
  });

  return (
    <Box
      sx={{
        minWidth: { md: "500px", xs: "400px" },
        maxWidth: { md: "500px", xs: "400px" },
      }}
    >
      <Box
        sx={{
          p: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Восстановление пароля</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box sx={{ pb: 2, px: 2 }}>
        <form noValidate onSubmit={onSubmit}>
          {status == "email" && (
            <Typography sx={{ mb: 2 }} variant="body1" align="center">
              Укажите E-mail, который был указан при регистрации. Мы вышлем на
              него письмо с кодом для сброса пароля
            </Typography>
          )}
          {status == "code" && (
            <Typography sx={{ mb: 2 }} variant="body1" align="center">
              Введите код полученый по E-mail
            </Typography>
          )}
          {(status == "email" || status == "code") && alert.text && (
            <Alert
              sx={{
                mt: 1,
              }}
              severity={alert.severity}
            >
              {alert.text}
            </Alert>
          )}
          {status == "email" && (
            <React.Fragment>
              <Controller
                name={"email"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    error={Boolean(errors.email?.message)}
                    {...register("email", {
                      required: "Обязательное поле!",
                      maxLength: 100,
                      pattern: {
                        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                        message: "Некорректный Email!",
                      },
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    sx={{ mt: 2 }}
                    autoFocus
                    helperText={errors.email?.message}
                    label="Email"
                    name="email"
                    type="email"
                    variant="outlined"
                    onChange={(e) => {
                      setAlert({ text: "" });
                      onChange(e);
                    }}
                    value={value}
                  />
                )}
              />
            </React.Fragment>
          )}
          {status == "code" && (
            <Controller
              name={"code"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  error={Boolean(errors.code?.message)}
                  {...register("code", {
                    required: "Обязательное поле!",
                    maxLength: 20,
                  })}
                  fullWidth
                  margin="normal"
                  autoFocus
                  helperText={errors.code?.message}
                  label="Код"
                  name="code"
                  type="text"
                  variant="outlined"
                  onChange={(e) => {
                    setAlert({ text: "" });
                    onChange(e);
                  }}
                  value={value}
                />
              )}
            />
          )}
          {status == "finish" && (
            <>
              <Typography sx={{ mb: 2 }} variant="body1" align="center">
                Пароль от аккаунта успешно изменён и отправлен вам на Email!
              </Typography>

              <Button
                fullWidth
                size="large"
                variant="contained"
                onClick={() => EventBus.$emit("show_dialog", "login")}
              >
                Продолжить авторизацию
              </Button>
            </>
          )}
          {(status == "code" || status == "email") && (
            <Button
              sx={{
                mt: 3,
              }}
              startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmitting}
              color="primary"
              type="submit"
              fullWidth
              size="large"
              variant="contained"
            >
              {status == "email" ? "Отправить код" : "Сбросить пароль"}
            </Button>
          )}
        </form>
      </Box>
    </Box>
  );
}
