import React from "react";
import { Box, Typography, FormControlLabel, Checkbox } from "@mui/material";
import { Link } from "react-router-dom";

function Agreement({ style, agree, setAgree }) {
  return (
    <Box sx={style}>
      <FormControlLabel
        control={
          <Checkbox
            checked={agree}
            onChange={(e) => setAgree(e.target.checked)}
            defaultChecked
          />
        }
        label={
          <Typography variant="body1" fontWeight="normal">
            Я согласен с обработкой персональных данных и принимаю
            <Typography
              component={Link}
              sx={{ color: "#3bd2a2" }}
              to="agreement"
            >
              правила обмена и политику AML/KYC
            </Typography>
          </Typography>
        }
      />
    </Box>
  );
}

export default Agreement;
