import {
  Box,
  Typography,
  styled,
  alpha,
  Avatar,
  Button,
  Card,
  Grid,
  CircularProgress,
  Divider,
  useTheme,
} from "@mui/material";
import { useCountdown } from "./methods/countDown";
import CopyToClipboard from "../CopyToClipboard/CopyToClipboard";


export default function PaymentTime({ amount, code, finish }) {
  const [minutes, seconds] = useCountdown(finish);

  let time = minutes + seconds <= 0 ? "00:00" : formatTime(minutes, seconds);

  return (
    <Grid spacing={2} container alignItems="center">
      <Grid item xs={6} md={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: {
              sx: "flex-start",
              md: "flex-end",
            },
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{color: '#475259'}} variant="body1" fontWeight="normal">
            Осталось времени:
          </Typography>
          <Typography variant="h5" fontWeight="normal">
            {time}
          </Typography>
        </Box>
      </Grid>
      <Grid xs={6} item md={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: {
              sx: "flex-start",
              md: "flex-end",
            },
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{color: '#475259'}} variant="body1" fontWeight="normal">
            К оплате:
          </Typography>
          <Box sx={{ display: "flex", alignItmes: "center" }}>
            <Typography sx={{ mr: 1 }} variant="h5" fontWeight="normal">
              {amount} {code}
            </Typography>
            <CopyToClipboard value={amount} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

function formatTime(min, sec) {
  let minutes = `${min}`.length < 2 ? `0${min}` : min;
  let seconds = `${sec}`.length < 2 ? `0${sec}` : sec;

  return `${minutes}:${seconds}`;
}
