import getPrecision from "../../../common/getPrecision";

export default function getRateText(exchange) {
  let rate = exchange.rate;
  let leftAmount;
  let rightAmount;
  let takePrecision = exchange.currency.take.precision;
  let givePrecision = exchange.currency.give.precision;
  let leftCode = exchange.currency.take.code;
  let rightCode = exchange.currency.give.code;
  if (rate.reverse == "left") {
    leftAmount = getPrecision(takePrecision, rate.service);
    rightAmount = 1;
  } else if (rate.reverse == "right") {
    leftAmount = 1;
    rightAmount = getPrecision(givePrecision, rate.service);
  } else {
    leftAmount = 1;
    rightAmount = 1;
  }

  return `${leftAmount} ${leftCode} = ${rightAmount} ${rightCode}`;
}
