import React from "react";
import { Typography, Box, Avatar, Button, Link } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import getImgUrl from "../../common/getImgUrl";

function Transaction({ img, address, link, style = {} }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        ...style,
      }}
    >
      <Avatar src={getImgUrl(img)} sx={{ width: 40, height: 40, mr: 1 }} />

      <Typography sx={{ mr: 1 }} noWrap variant="body1" fontWeight={400}>
        {address}
      </Typography>

      <Button
        size="small"
        variant="contained"
        component={Link}
        sx={{
          minWidth: "max-content",
          color: "white",
          textShadow: "1px 1px 1px #475259c4",
        }}
        href={link}
        target="_blank"
        endIcon={<LinkIcon />}
      >
        Транзакция
      </Button>
    </Box>
  );
}

export default Transaction;
