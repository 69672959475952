import React from "react";
import { Card, Typography, Divider, Box, Grid } from "@mui/material";

import ExchangePair from "./ExchangePair";

function Rejected({ order }) {
  let comment =
    order.comment && order.comment.client
      ? order.comment.client
      : "Заявка была отклоненна так как мы не получили от вас средств в течении указанного времени";

  return (
    <Card>
      <Box sx={{ pt: 2, px: 2 }}>
        <Typography sx={{ mb: 1.5 }} variant="h5" fontWeight="normal">
          Заявка отклонена
        </Typography>
        <Box
          sx={{
            p: 2,
            bgcolor: "#d3d3d36e",
            borderRadius: 1.5,
          }}
        >
          <Typography variant="body1" textAlign="center">
            Причина:
          </Typography>
          <Typography
            sx={{ color: "red" }}
            align="center"
            variant="h6"
            fontWeight={400}
          >
            {comment}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ mt: 1 }} />
      <Box sx={{ px: 2, py: 1 }}>
        <Grid alignItems="center" container spacing={2}>
          <Grid item xs={12} md={6}>
            <ExchangePair
              take={{
                value: order.fields.take.find((f) => f.isAddress || f.isCard)
                  ?.value,
                name: order.currency.take.name,
                code: order.currency.take.code,
                img: order.currency.take.image,
                amount: order.amount.take,
              }}
              give={{
                value: order.fields.give.find((f) => f.isAddress || f.isCard)
                  ?.value,
                name: order.currency.give.name,
                code: order.currency.give.code,
                img: order.currency.give.image,
                amount: order.amount.give,
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

export default Rejected;
