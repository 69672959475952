import React, { useState } from "react";
import {

  Box,

  Alert,
  Button,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import changeImage from "./methods/changeImage";

function UploadFile({ setImage, styles }) {
  const [pic, setPic] = useState(null);

  function changeImageHandler(e) {
    changeImage(e, setPic, setImage);
  }

  return (
    <Box
      sx={{

        mt: 1,
        backgroundColor: "white",
        borderRadius: "10px",
        py: 2,
        px: 1,
        flexGrow: 1,
        mb: 1,
        border: "1px dashed #475259c4",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...styles
      }}
    >
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={changeImageHandler}
        id="contained-button-file"
      />
      {!pic && (
        <Button
          component="label"
          htmlFor="contained-button-file"
          variant="contained"
          startIcon={<CloudUploadIcon />}
        >
          Загрузить фото
        </Button>
      )}
      {pic && (
        <Box>
          <label
            htmlFor="contained-button-file"
            sx={{
              display: "block",
              width: "100%",
              borderRadius: "5px",
              overflow: "hidden",
            }}
          >
            <img src={pic} sx={{ display: "block" }} width="100%" alt="Карта" />
          </label>
          <Alert icon={false} severity="info">
            Нажмите, чтобы изменить изображение
          </Alert>
        </Box>
      )}
    </Box>
  );
}

export default UploadFile;
