import getExchangeAmount from "../../../common/getExchangeAmount";
import getCommissionBtc from "./getCommissionBtc";
import getPrecision from "../../../common/getPrecision";

export default function calculateGive(
  exchange,
  amountTake,
  setGive,
  setCommissionInfo,
  setMinLimit
) {
  let precision = exchange.currency.give.precision;
  let give = getExchangeAmount(exchange, "give", amountTake);

  const commissionBTC = getCommissionBtc(
    exchange,
    amountTake,
    give,
    "take",
    setCommissionInfo,
    setMinLimit
  );

  if (commissionBTC) {
    const calcAmountGive = getPrecision(precision, give - commissionBTC);
    if (calcAmountGive > 0) {
      give = calcAmountGive;
    } else {
      give = 0;
    }
  }

  //   let commissionQiwi = this.getCommissionQiwi;

  //   if (commissionQiwi) {
  //     const subGive = give * commissionQiwi;
  //     const calcAmountGive =
  //       Math.floor((give - subGive) * this.precision.give) / this.precision.give;
  //     if (calcAmountGive > 0) {
  //       give = calcAmountGive;
  //     } else {
  //       give = 0;
  //     }
  //   }

  setGive(+give);
}
