import React, { useState } from "react";
import {
  Typography,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";

import QuizIcon from "@mui/icons-material/Quiz";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import CurrencyRubleIcon from "@mui/icons-material/CurrencyRuble";
import HandshakeIcon from "@mui/icons-material/Handshake";
import PhonelinkIcon from "@mui/icons-material/Phonelink";

const list = [
  {
    to: "/faqs",
    name: "FAQ",
    icon: QuizIcon,
  },
  // {
  //   to: "/news",
  //   name: "Новости",
  //   icon: NewspaperIcon,
  // },
  {
    to: "/reserves",
    name: "Резервы",
    icon: CurrencyRubleIcon,
  },
  {
    to: "/agreement",
    name: "Соглашение",
    icon: HandshakeIcon,
  },
  {
    to: "/contacts",
    name: "Контакты",
    icon: PhonelinkIcon,
  },
];

export default function Main() {
  const { pathname } = useLocation();

  return (
    <MenuList sx={{ fontSize: "24px" }}>
      {list.map((n) => {
        let menuItemStyle = Object.assign({}, styles.menuItem);
        if (pathname === n.to) {
          menuItemStyle.bgcolor = "#ffffff3d";
        }

        return (
          <MenuItem component={NavLink} to={n.to} key={n.to} sx={menuItemStyle}>
            <ListItemIcon sx={styles.listItemIcon}>
              <n.icon sx={styles.icon} />
            </ListItemIcon>
            <ListItemText>
              <Typography sx={styles.listItemText}>{n.name}</Typography>
            </ListItemText>
          </MenuItem>
        );
      })}
    </MenuList>
  );
}

const styles = {
  menuItem: {
    mb: 1,
    borderRadius: "25px",
  },
  listItemIcon: {
    color: "white",
  },
  icon: {
    fontSize: 30,
  },
  listItemText: {
    color: "white",
    fontSize: "22px",
    fontWeight: 400,
    textShadow: "0px 0px 2px #000",
    ml: 1,
  },
};
