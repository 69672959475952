import { useState, useEffect } from "react";

import { socket } from "../../socket";
import ExchangeService from "../../services/Exchange";

const ExchangeState = (props) => {
  let { takeSelectedCurrency, giveSelectedCurrency, exchanges } = props;
  const [exchangeId, setExchangeId] = useState(null);
  const [exchange, setExchange] = useState(null);
  const [isFetchExchange, setIsFetchExchange] = useState(null);

  useEffect(() => {
    if (!(takeSelectedCurrency && giveSelectedCurrency)) return;

    if (exchangeId) {
      socket.off(`update_exchange_${exchangeId}`);
    }

    let findExchange = exchanges.find(
      (e) =>
        e.take._id == takeSelectedCurrency._id &&
        e.give._id == giveSelectedCurrency._id
    );
    if (!findExchange) return;

    setExchangeId(findExchange._id);
  }, [takeSelectedCurrency, giveSelectedCurrency]);

  useEffect(() => {
    socket.on(`update_exchange_${exchangeId}`, (e) => {
      setExchange((exc) => {
        if (exc && e._id == exc._id) {
          return e;
        } else {
          return exc;
        }
      });
      console.log(e, "update exchange");
    });

    if (isFetchExchange || !exchangeId) return;

    setIsFetchExchange(true);
    ExchangeService.getOne({ id: exchangeId }).then((response) => {
      let newExchange = response.data;
      setExchange(newExchange);
      setIsFetchExchange(false);
    });
  }, [exchangeId]);

  return {
    exchange,
    isFetchExchange
  }
};

export default ExchangeState;
