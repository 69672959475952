import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Card,
  Paper,
  Container,
  Modal,
  Dialog,
} from "@mui/material";
import Login from "./Auth/Login";
import Reg from "./Auth/Reg";
import RecoveryPassword from "./Auth/RecoveryPassword";

import { EventBus } from "../event";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "hidden",
};

function GlobalModal() {
  const [showDialog, setShowDialog] = useState("");

  useEffect(() => {
    EventBus.$on("show_dialog", (name) => {
      setShowDialog(name);
    });

    return () => {
      EventBus.$off("show_dialog");
    };
  }, []);

  const handleClose = () => setShowDialog("");

  function view(n) {
    switch (n) {
      case "login":
        return <Login handleClose={handleClose} />;
      case "reg":
        return <Reg handleClose={handleClose} />;
      case "recovery_password":
        return <RecoveryPassword handleClose={handleClose} />;
    }
  }

  return (
    <Box>
      <Modal onClose={handleClose} open={!!showDialog}>
        <Card sx={style}>{view(showDialog)}</Card>
      </Modal>
    </Box>
  );
}

export default GlobalModal;
