import sortCurrencies from "./sortCurrencies";

export default function getCurrenciesInExchanges(exchanges) {
  let takeCurrencies = exchanges.map((e) => e.take);
  let giveCurrencies = exchanges.map((e) => e.give);
  let allCurrencies = [...takeCurrencies, ...giveCurrencies];
  allCurrencies = allCurrencies.filter((c) => c.reserve);
  let uniqueCurrencies = allCurrencies.filter((c, i, arr) => {
    return arr.findIndex((item) => item._id === c._id) === i;
  });

  uniqueCurrencies = uniqueCurrencies
    .sort(sortCurrencies)
    .sort((a, b) => a.sort - b.sort);
  return uniqueCurrencies;
}
