import { Card, Container, Typography, Box, IconButton } from "@mui/material";
import parse from "html-react-parser";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
moment.locale("ru");

let style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "90vw",
  width: '500px'
};

function NewsModalContent({ news, closeHandler }) {
  const { title, body, created } = news;

  return (
    <Card sx={style}>
      <Box
        sx={{
          py: 0.5,
          pt: 1,
          px: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 400 }}>
          {title}
        </Typography>
        <IconButton onClick={closeHandler}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ p: 2 }}>
        <Typography variant="body1" component="div">
          {parse(body)}
        </Typography>
        <Typography variant="body2" align="right" sx={{ mt: 2, color: '#475259c4' }}>
          {moment(created).format("D MMM YYYY")}
        </Typography>
      </Box>
    </Card>
  );
}

export default NewsModalContent;
