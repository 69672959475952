import axios from "axios";
import ProfileStore from "../store/profile";

class CardService {
  getAll() {
    return axios.get("profile/cards");
  }
  getOne(hash) {
    return axios.get("profile/cards/one", { params: { hash } });
  }
  reverify(data, hash) {
    return axios.put("profile/cards/reverification", data, {
      params: {
        hash,
      },
    });
  }
  add(data) {
    return axios.post("profile/cards", data);
  }
}

const cardService = new CardService();

export default cardService;
