import axios from "axios";

class Profile {
  signUp(data) {
    return axios.post("signup", data);
  }
  signIn(data) {
    return axios.post("signin", data);
  }
  resetPassword(data) {
    return axios.put("profile/resetPassword", data);
  }
  getProfile() {
    return axios.get("profile");
  }
  changeProfile(data) {
    return axios.put("profile", data);
  }
  changePassword(data) {
    return axios.put("profile/password", data);
  }
  deleteAccount() {
    return axios.put("profile/delete");
  }
}

const profile = new Profile({});

export default profile;
