import OrderService from "../services/Order";

export default async function fetchUnauthOrder(navigate) {
  try {
    const orderHash = localStorage.getItem("orderHash");

    if (orderHash) {
      let response = await OrderService.getOne(orderHash);
      let orderData = response?.data;
      if (orderData) {
        navigate(`/orders/${orderData.hash}`);
      }
    }
  } catch (e) {
    console.error(e);
    localStorage.removeItem("orderToken");
  }
}
