import React, { useState } from "react";
import {
  Box,
  Typography,
  styled,
  alpha,
  Avatar,
  Button,
  Card,
  Grid,
  CircularProgress,
  Divider,
  useTheme,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CancelIcon from "@mui/icons-material/Cancel";
import ExchangePair from "./ExchangePair";
import getImgUrl from "../../common/getImgUrl";
import Field from "./Field";
import LoadingButton from "@mui/lab/LoadingButton";
import CurrencyImg from "../CurrencyImg/CurrencyImg";
import config from "../../config";
import OrderService from "../../services/Order";
import CurrencyCard from "../CurrencyCard/CurrencyCard";
import PaymentTime from "./PaymentTime";

function Payment({ order }) {
  let [loading, setLoading] = useState("");

  function changeOrder(type) {
    setLoading(type);
    OrderService.change({ hash: order.hash, type })
      .then((res) => {
        console.log(res.data);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading("");
      });
  }

  // let paymentLink = order.paymentLink
  //   ? `${config.API}${order.paymentLink}?orderId=${order._id}`
  //   : null;

  // // console.log(paymentLink, "paymentLink");

  let qrcode = order.qrcode ? getImgUrl(order.qrcode) : null;
  // qrcode = false;
  return (
    <Card>
      <Box>
        <Box sx={{ pt: 2, px: 2 }}>
          <Box>
            <Grid alignItems="flex-end" container spacing={2}>
              <Grid item xs={12} md={7}>
                <Typography ssx={{ mb: 1.5 }} variant="h5" fontWeight="normal">
                  Оплатите реквизиты
                </Typography>
                <Typography sx={{ mb: 0.5, color: "#475259" }} variant="body1">
                  Оплатите выставленные ниже счета удобным вам способом в
                  течении 15 минут.
                </Typography>
                <Typography sx={{ mb: 0.5, color: "#475259" }} variant="body1">
                  После чего нажмите кнопку "Я оплатил", чтобы заявка перешла на
                  следующий этап обработки.
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <PaymentTime
                  amount={order.amount.take}
                  code={order.currency.take.code}
                  finish={order.finished}
                />
              </Grid>
            </Grid>
          </Box>

          <Grid alignItems="stretch" sx={{ my: 2 }} container spacing={2}>
            <Grid item xs={12} md={7}>
              <Box sx={{ p: 1, bgcolor: "#d3d3d36e", borderRadius: 1.5 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <Avatar
                    src={getImgUrl(order.currency.take.image)}
                    sx={{ width: 40, height: 40 }}
                  />
                  <Typography
                    sx={{ color: "#475259c4", ml: 1 }}
                    variant="body1"
                  >
                    {order.currency.take.name}
                  </Typography>
                </Box>
                {order.fields.info.map((f) => {
                  return <Field name={f.name} value={f.value} />;
                })}
              </Box>
            </Grid>
            {qrcode && (
              <Grid item xs={12} md={5}>
                <Box
                  sx={{
                    bgcolor: "#e3f2fa",
                    p: 1,
                    height: "100%",
                    borderRadius: 1.5,
                  }}
                >
                  <Typography
                    sx={{ my: 0.4 }}
                    variant="subtitle1"
                    align="center"
                  >
                    QR-code
                  </Typography>
                  <Avatar
                    variant="square"
                    alt="QR-code"
                    src={qrcode}
                    sx={{
                      width: { md: "90%", xs: "90%", sm: "50%" },
                      height: "auto",
                      marginX: "auto",
                      my: 1,
                    }}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box sx={{ p: 2 }}></Box>

        <Divider sx={{ mt: 1 }} />
        <Box sx={{ px: 2, py: 1 }}>
          <Grid alignItems="center" container spacing={2}>
            <Grid item xs={12} md={6}>
              <ExchangePair
                take={{
                  value: order.fields.take.find((f) => f.isAddress || f.isCard)
                    ?.value,
                  name: order.currency.take.name,
                  code: order.currency.take.code,
                  img: order.currency.take.image,
                  amount: order.amount.take,
                }}
                give={{
                  value: order.fields.give.find((f) => f.isAddress || f.isCard)
                    ?.value,
                  name: order.currency.give.name,
                  code: order.currency.give.code,
                  img: order.currency.give.image,
                  amount: order.amount.give,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItms: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={() => changeOrder("reject")}
                  variant="contained"
                  color="error"
                  endIcon={
                    loading == "reject" ? (
                      <CircularProgress size="1rem" />
                    ) : (
                      <CancelIcon />
                    )
                  }
                  disabled={loading}
                >
                  Отменить заявку
                </Button>
                <Button
                  onClick={() => changeOrder("payment")}
                  sx={{ ml: 1 }}
                  variant="contained"
                  endIcon={
                    loading == "payment" ? (
                      <CircularProgress size="1rem" />
                    ) : (
                      <ArrowForwardIosIcon />
                    )
                  }
                  disabled={loading}
                >
                  Я оплатил
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  );
}

export default Payment;
