import React from "react";
import { Card, Alert } from "@mui/material";

function NotifySection({ exchange }) {
  let takeNotification = exchange.currency.take.takeNotification;
  let giveNotification = exchange.currency.give.giveNotification;

  if (!takeNotification && !giveNotification) return null;

  return (
    <Card
      sx={{
        p: 0.5,
        mt: 2,
        boxShadow: "none",
      }}
    >
      {takeNotification && (
        <Alert icon={false} severity="info">
          {takeNotification}
        </Alert>
      )}

      {giveNotification && (
        <Alert icon={false} severity="info">
          {giveNotification}
        </Alert>
      )}
    </Card>
  );
}

export default NotifySection;
