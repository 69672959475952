export default function getTransactionLink(order, side) {
  let address = "";
  let link = order.currency[side].currency.transactionLink;
  let addressPayment = order.fields[side == "take" ? "info" : "give"].find(
    (field) => field.isAddress
  );
  if (link && addressPayment) {
    address = link + addressPayment.value;
  }

  if (!address) return null;

  return {
    address: addressPayment.value,
    link: address,
  };
}
