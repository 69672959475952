import countryList from "./country.json";
import getImgUrl from "./getImgUrl";

export default function mobileNumberHandler(val) {
  if (val.length == 0) {
    return {
      alt: "Не выбрано",
      img: "",
      value: val,
    };
  }

  let regionCode = val.slice(0, 4);

  let country = null;

  for (let key in countryList) {
    let countryCode = countryList[key].code;
    let countryCodeLength = countryCode.length;
    let regionCodeLength = regionCode.length;

    let minLength =
      countryCodeLength < regionCodeLength
        ? countryCodeLength
        : regionCodeLength;

    if (minLength === 0) break;

    let regionTrimm = regionCode.slice(0, minLength);
    let countryTrimm = countryCode.slice(0, minLength);

    if (regionTrimm === countryTrimm) {
      country = countryList[key];
    }
  }

  if (country) {
    return {
      alt: country.alt,
      img: getImgUrl(country.name),
      value: val,
    };
  }

  function getMinimalMatch(number, length) {
    let findCountry;

    for (let key in countryList) {
      let c = countryList[key];
      let trimmNumber = number.slice(0, length);
      let trimmCode = c.code.slice(0, length);

      if (trimmNumber === trimmCode) {
        findCountry = c;
      }
    }

    if (findCountry) {
      return { findCountry, length };
    } else {
      return false;
    }
  }

  if (!country) {
    let findMinimalMatch3 = getMinimalMatch(val, 3);
    let findMinimalMatch2 = getMinimalMatch(val, 2);
    let findMinimalMatch1 = getMinimalMatch(val, 1);

    let finded = findMinimalMatch3 || findMinimalMatch2 || findMinimalMatch1;

    if (!finded) {
      return {
        alt: "Не выбрано",
        img: "",
        value: "",
      };
    }

    return {
      alt: finded.findCountry.alt,
      img: getImgUrl(finded.findCountry.name),
      value: val.slice(0, finded.length),
    };
  }
}
